import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Chip,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { API_URL, modulos } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";

const ModalEditAdmin = ({ handleCloseModal, user }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  const [changePassword, setChangePassword] = useState(false);
  const handleChangePassword = () => {
    setChangePassword(true);
  };

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    id: user.id,
    UserName: user.UserName,
    permisos: user.permisos.split(","),
    Password: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateAgent = () => {
    if (formValues.UserName === "") {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/panel/edit-panel-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formValues,
          permisos: formValues.permisos.join(","),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast("Error en el servidor, intentelo más tarde", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Editar Usuario Panel
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="UserName"
        >
          Usuario
        </InputLabel>
        <Input
          id="UserName"
          name="UserName"
          value={formValues.UserName}
          onChange={handleChange}
        />
      </FormControl>
      {!changePassword && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleChangePassword}
          sx={{ mb: 2, mt: 2 }}
        >
          Cambiar Contraseña
        </Button>
      )}
      {changePassword && (
        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
          <InputLabel
            sx={{
              "&.Mui-focused": {
                color: "#ffffffb3",
              },
            }}
            htmlFor="Password"
          >
            Contraseña
          </InputLabel>
          <Input
            id="Password"
            name="Password"
            type="Password"
            value={formValues.Password}
            onChange={handleChange}
          />
        </FormControl>
      )}
      <Box>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
            fontSize: "15px",
          }}
        >
          Selecciona los permisos
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="permisos"
            name="permisos"
            multiple
            value={formValues.permisos}
            onChange={handleChange}
            variant="outlined"
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "5px",
                  height: "120px",
                  overflowY: "scroll",
                }}
              >
                {selected.map((value) => {
                  const modulo = modulos.find((modulo) => modulo === value);
                  if (modulo) {
                    return <Chip key={modulo} label={modulo} />;
                  }
                  return null;
                })}
              </div>
            )}
          >
            {modulos.map((modulo) => {
              return (
                <MenuItem
                  key={`modulo-${modulo}`}
                  value={modulo}
                  style={{
                    backgroundColor: formValues.permisos?.includes(modulo)
                      ? colors.greenAccent[500]
                      : "transparent",
                    color: formValues.permisos?.includes(modulo)
                      ? "#000"
                      : "#fff",
                  }}
                >
                  {modulo}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateAgent}
        >
          Guardar
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalEditAdmin;
