import Header from "../../components/Header";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  API_URL,
  countries,
  provincesByCountry,
} from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";

dayjs.locale(esLocale);

const CreateAgent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    country: "spain",
    entidad_deportiva: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.name === "" ||
      formValues.lastname === "" ||
      formValues.email === "" ||
      formValues.country === "" ||
      formValues.password === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else if (formValues.password.length < 8) {
      showToast({
        password: "La contraseña debe tener al menos 8 caracteres.",
      });
    } else {
      fetch(`${API_URL}auth/panel/agent-nuevo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormValues({
              name: "",
              lastname: "",
              email: "",
              country: "España",
              state: "",
              date: dayjs(),
              password: "",
            });
            showToast(
              "Su cuenta ha sido creada, su información sera revisada por uno de nuestros administradores y le llegara la confirmacion por correo.",
              "success"
            );
          } else {
            showToast(data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          setFormValues({
            name: "",
            lastname: "",
            email: "",
            country: "España",
            state: "",
            date: dayjs(),
            password: "",
          });
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      m="20px auto"
      width="100%"
      maxWidth="500px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <img
        alt="profile-user"
        width="140px"
        height="auto"
        src={`../../assets/logo.svg`}
        style={{ cursor: "pointer", marginBottom: "20px" }}
      />
      <Header
        title="Creación de cuenta de Agente"
        subtitle="Un administrador debera aprobar tu cuenta para que puedas comenzar a usarla."
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="name"
        >
          Nombre
        </InputLabel>
        <Input
          id="name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="lastname"
        >
          Apellidos
        </InputLabel>
        <Input
          id="lastname"
          name="lastname"
          value={formValues.lastname}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="email"
        >
          Correo
        </InputLabel>
        <Input
          id="email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="entidad_deportiva"
        >
          Entidad Deportiva
        </InputLabel>
        <Input
          id="entidad_deportiva"
          name="entidad_deportiva"
          value={formValues.entidad_deportiva}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="password"
        >
          Contraseña
        </InputLabel>
        <Input
          id="password"
          name="password"
          type="password"
          value={formValues.password}
          onChange={handleChange}
        />
      </FormControl>

      <Box sx={{ mb: 2, mt: 2, width: "100%" }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
            fontSize: "15px",
          }}
        >
          País
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="country"
            name="country"
            value={formValues.country}
            onChange={handleChange}
            variant="outlined"
          >
            {Object.keys(countries).map((countryKey) => (
              <MenuItem
                key={`pais-${countryKey}`}
                value={countryKey}
                style={{
                  backgroundColor:
                    formValues.Pais === countryKey
                      ? colors.greenAccent[500]
                      : "transparent",
                  color: formValues.Pais === countryKey ? "#000" : "#fff",
                }}
              >
                {countries[countryKey] || "Desconocido"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button variant="contained" color="secondary" onClick={handleCreateAgent}>
        Crear
      </Button>
    </Box>
  );
};

export default CreateAgent;
