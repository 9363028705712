import Header from "../../components/Header";
import { useState } from "react";
import { Box, FormControl, InputLabel, Input, Button } from "@mui/material";
const Form = () => {
  const [changePassword, setChangePassword] = useState(false);
  const [formValues, setFormValues] = useState({
    userId: 0,
    user: "admin_bro",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangePassword = () => {
    setChangePassword(true);
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <Box m="20px">
      <Header
        title="CONFIGURACIÓN"
        subtitle="Recuerda estas credenciales para poder loguearte"
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="User"
        >
          Usuario
        </InputLabel>
        <Input
          id="User"
          name="User"
          value={formValues.user}
          onChange={handleChange}
        />
      </FormControl>
      {!changePassword && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleChangePassword}
          sx={{ mb: 2, mt: 2 }}
        >
          Cambiar Contraseña
        </Button>
      )}
      {changePassword && (
        <>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="oldPassword"
            >
              Contraseña antigua
            </InputLabel>
            <Input
              id="oldPassword"
              name="oldPassword"
              type="password"
              value={formValues.oldPassword}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="newPassword"
            >
              Contraseña nueva
            </InputLabel>
            <Input
              id="newPassword"
              name="newPassword"
              type="password"
              value={formValues.newPassword}
              onChange={handleChange}
            />
          </FormControl>{" "}
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="confirmPassword"
            >
              Confirme la contraseña
            </InputLabel>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={formValues.confirmPassword}
              onChange={handleChange}
            />
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default Form;
