import { Box, Typography, useTheme, Stack, IconButton } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ModalSendEmail from "../../components/consultas/ModalSendEmail";
import EmailIcon from "@mui/icons-material/Email";

const Consultas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [consultas, setConsultas] = useState([]);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [correoToSend, setCorreoToSend] = useState("");
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };
  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };
  useEffect(() => {
    if (shouldOpenModal && correoToSend) {
      handleOpenModalEdit();
      setShouldOpenModal(false);
    }
  }, [shouldOpenModal, correoToSend]);

  const handleOpenModalEdit = () => {
    setIsModalOpenEdit(true);
  };
  const handleCloseModalEdit = () => {
    setIsModalOpenEdit(false);
  };

  const fecthConsultas = () => {
    fetch(`${API_URL}auth/panel/all-consultas`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setConsultas(data.consultas.reverse());
        } else {
          setConsultas([]);
        }
      })
      .catch((err) => {
        setConsultas([]);
        console.log(err);
      });
  };
  const handleEdit = (id) => {
    const consulta = consultas.filter((consulta) => consulta.id === id);
    setCorreoToSend(consulta[0].email);
    setIsModalOpenEdit(true);
  };
  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Nombre",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.name} {row.lastname}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Correo ",
      flex: isMobile ? "none" : 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Servicio por el que consulta",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => handleEdit(row.id)}
              color="secondary"
              aria-label="Editar"
            >
              <EmailIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fecthConsultas();
  }, []);

  return (
    <Box m="20px">
      <Header
        title="Consultas"
        subtitle="Revisa los Consultas de los usuarios que preguntas por los servicios"
      />
      {
        <Modal open={isModalOpenEdit} onClose={handleCloseModalEdit}>
          <div>
            <ModalSendEmail
              handleCloseModal={handleCloseModalEdit}
              correo={correoToSend}
            />
          </div>
        </Modal>
      }
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este video?"}
        content={"El usuario ya no lo verá y no podrá recuperarlo"}
        handleConfirm={() => {}}
      />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },

          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={consultas}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Consultas;
