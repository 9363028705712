export const API_URL = "https://back.xn--br-6ja.com/";
//export const API_URL = "http://localhost:8080/";
export const plans = ["Basic", "Gold", "Platinum", "Unlimited"];
export const countries = {
  spain: "España",
  france: "Francia",
  italy: "Italia",
  germany: "Alemania",
  portugal: "Portugal",
  uk: "Reino Unido",
  ireland: "Irlanda",
  netherlands: "Países Bajos",
  belgium: "Bélgica",
  luxembourg: "Luxemburgo",
  switzerland: "Suiza",
  austria: "Austria",
  poland: "Polonia",
  czech_republic: "República Checa",
  hungary: "Hungría",
  slovakia: "Eslovaquia",
  slovenia: "Eslovenia",
  croatia: "Croacia",
  serbia: "Serbia",
  bosnia: "Bosnia",
  greece: "Grecia",
  cyprus: "Chipre",
  bulgaria: "Bulgaria",
  romania: "Rumanía",
  albania: "Albania",
  denmark: "Dinamarca",
  sweden: "Suecia",
  norway: "Noruega",
  finland: "Finlandia",
  iceland: "Islandia",
  estonia: "Estonia",
  latvia: "Letonia",
  lithuania: "Lituania",
  russia: "Rusia",
  ukraine: "Ucrania",
  belarus: "Bielorrusia",
  moldova: "Moldavia",
  montenegro: "Montenegro",
  kosovo: "Kosovo",
  north_macedonia: "Macedonia del Norte",
  malta: "Malta",
  usa: "Estados Unidos",
  canada: "Canadá",
  mexico: "México",
  brazil: "Brasil",
  argentina: "Argentina",
  chile: "Chile",
  colombia: "Colombia",
  venezuela: "Venezuela",
  peru: "Perú",
  uruguay: "Uruguay",
  paraguay: "Paraguay",
  ecuador: "Ecuador",
  bolivia: "Bolivia",
  costa_rica: "Costa Rica",
  panama: "Panamá",
  guatemala: "Guatemala",
  honduras: "Honduras",
  el_salvador: "El Salvador",
  nicaragua: "Nicaragua",
  cuba: "Cuba",
  dominican_republic: "República Dominicana",
  puerto_rico: "Puerto Rico",
  china: "China",
  japan: "Japón",
  south_korea: "Corea del Sur",
  india: "India",
  indonesia: "Indonesia",
  vietnam: "Vietnam",
  philippines: "Filipinas",
  thailand: "Tailandia",
  malaysia: "Malasia",
  singapore: "Singapur",
  pakistan: "Pakistán",
  bangladesh: "Bangladés",
  saudi_arabia: "Arabia Saudita",
  united_arab_emirates: "Emiratos Árabes Unidos",
  israel: "Israel",
  iran: "Irán",
  iraq: "Irak",
  turkey: "Turquía",
  kazakhstan: "Kazajistán",
  nigeria: "Nigeria",
  south_africa: "Sudáfrica",
  egypt: "Egipto",
  kenya: "Kenia",
  ethiopia: "Etiopía",
  ghana: "Ghana",
  morocco: "Marruecos",
  tanzania: "Tanzania",
  algeria: "Argelia",
  uganda: "Uganda",
  angola: "Angola",
  senegal: "Senegal",
  cameroon: "Camerún",
  zimbabwe: "Zimbabue",
  ivory_coast: "Costa de Marfil",
  afghanistan: "Afganistán",
  andorra: "Andorra",
  antigua_and_barbuda: "Antigua y Barbuda",
  armenia: "Armenia",
  australia: "Australia",
  azerbaijan: "Azerbaiyán",
  bahamas: "Bahamas",
  barbados: "Barbados",
  bahrain: "Baréin",
  belize: "Belice",
  benin: "Benín",
  burkina_faso: "Burkina Faso",
  burundi: "Burundi",
  bhutan: "Bután",
  cape_verde: "Cabo Verde",
  cambodia: "Camboya",
  qatar: "Catar",
  chad: "Chad",
  vatican_city: "Ciudad del Vaticano",
  comoros: "Comoras",
  north_korea: "Corea del Norte",
  cote_d_ivoire: "Costa de Marfil",
  dominica: "Dominica",
  eritrea: "Eritrea",
  fiji: "Fiyi",
  gabon: "Gabón",
  gambia: "Gambia",
  georgia: "Georgia",
  grenada: "Granada",
  guyana: "Guyana",
  guinea: "Guinea",
  equatorial_guinea: "Guinea Ecuatorial",
  guinea_bissau: "Guinea-Bisáu",
  haiti: "Haití",
  kyrgyzstan: "Kirguistán",
  kiribati: "Kiribati",
  kuwait: "Kuwait",
  laos: "Laos",
  lesotho: "Lesoto",
  lebanon: "Líbano",
  liberia: "Liberia",
  libya: "Libia",
  liechtenstein: "Liechtenstein",
  madagascar: "Madagascar",
  malawi: "Malaui",
  maldives: "Maldivas",
  mali: "Malí",
  mauritius: "Mauricio",
  mauritania: "Mauritania",
  micronesia: "Micronesia",
  monaco: "Mónaco",
  mongolia: "Mongolia",
  mozambique: "Mozambique",
  namibia: "Namibia",
  nauru: "Nauru",
  nepal: "Nepal",
  niger: "Níger",
  new_zealand: "Nueva Zelanda",
  palau: "Palaos",
  papua_new_guinea: "Papúa Nueva Guinea",
  rwanda: "Ruanda",
  saint_kitts_and_nevis: "San Cristóbal y Nieves",
  san_marino: "San Marino",
  saint_vincent_and_the_grenadines: "San Vicente y las Granadinas",
  saint_lucia: "Santa Lucía",
  sao_tome_and_principe: "Santo Tomé y Príncipe",
  seychelles: "Seychelles",
  sierra_leone: "Sierra Leona",
  syria: "Siria",
  somalia: "Somalia",
  sri_lanka: "Sri Lanka",
  swaziland: "Suazilandia",
  sudan: "Sudán",
  south_sudan: "Sudán del Sur",
  suriname: "Surinam",
  tajikistan: "Tayikistán",
  timor_leste: "Timor-Leste",
  togo: "Togo",
  tonga: "Tonga",
  trinidad_and_tobago: "Trinidad y Tobago",
  tunisia: "Túnez",
  turkmenistan: "Turkmenistán",
  tuvalu: "Tuvalu",
  uzbekistan: "Uzbekistán",
  vanuatu: "Vanuatu",
  yemen: "Yemen",
  djibouti: "Yibuti",
  zambia: "Zambia",
};

export const provincesByCountry = {
  spain: {
    "A Coruña": "A Coruña",
    Álava: "Álava",
    Albacete: "Albacete",
    Alicante: "Alicante",
    Almería: "Almería",
    Asturias: "Asturias",
    Ávila: "Ávila",
    Badajoz: "Badajoz",
    Baleares: "Baleares",
    Barcelona: "Barcelona",
    Burgos: "Burgos",
    Cáceres: "Cáceres",
    Cádiz: "Cádiz",
    Cantabria: "Cantabria",
    Castellón: "Castellón",
    Ceuta: "Ceuta",
    "Ciudad Real": "Ciudad Real",
    Córdoba: "Córdoba",
    Cuenca: "Cuenca",
    Girona: "Girona",
    Granada: "Granada",
    Guadalajara: "Guadalajara",
    Gipuzkoa: "Gipuzkoa",
    Huelva: "Huelva",
    Huesca: "Huesca",
    Jaén: "Jaén",
    "La Rioja": "La Rioja",
    "Las Palmas": "Las Palmas",
    León: "León",
    Lleida: "Lleida",
    Lugo: "Lugo",
    Madrid: "Madrid",
    Málaga: "Málaga",
    Melilla: "Melilla",
    Murcia: "Murcia",
    Navarra: "Navarra",
    Ourense: "Ourense",
    Palencia: "Palencia",
    Pontevedra: "Pontevedra",
    Salamanca: "Salamanca",
    Segovia: "Segovia",
    Sevilla: "Sevilla",
    Soria: "Soria",
    Tarragona: "Tarragona",
    "Santa Cruz de Tenerife": "Santa Cruz de Tenerife",
    Teruel: "Teruel",
    Toledo: "Toledo",
    Valencia: "Valencia",
    Valladolid: "Valladolid",
    Vizcaya: "Vizcaya",
    Zamora: "Zamora",
    Zaragoza: "Zaragoza",
  },
  usa: {
    Alabama: "Alabama",
    Alaska: "Alaska",
    Arizona: "Arizona",
    Arkansas: "Arkansas",
    California: "California",
    Colorado: "Colorado",
    Connecticut: "Connecticut",
    Delaware: "Delaware",
    "District of Columbia": "District of Columbia",
    Florida: "Florida",
    Georgia: "Georgia",
    Hawaii: "Hawaii",
    Idaho: "Idaho",
    Illinois: "Illinois",
    Indiana: "Indiana",
    Iowa: "Iowa",
    Kansas: "Kansas",
    Kentucky: "Kentucky",
    Louisiana: "Louisiana",
    Maine: "Maine",
    Maryland: "Maryland",
    Massachusetts: "Massachusetts",
    Michigan: "Michigan",
    Minnesota: "Minnesota",
    Mississippi: "Mississippi",
    Missouri: "Missouri",
    Montana: "Montana",
    Nebraska: "Nebraska",
    Nevada: "Nevada",
    "New Hampshire": "New Hampshire",
    "New Jersey": "New Jersey",
    "New Mexico": "New Mexico",
    "New York": "New York",
    "North Carolina": "North Carolina",
    "North Dakota": "North Dakota",
    Ohio: "Ohio",
    Oklahoma: "Oklahoma",
    Oregon: "Oregon",
    Pennsylvania: "Pennsylvania",
    "Rhode Island": "Rhode Island",
    "South Carolina": "South Carolina",
    "South Dakota": "South Dakota",
    Tennessee: "Tennessee",
    Texas: "Texas",
    Utah: "Utah",
    Vermont: "Vermont",
    Virginia: "Virginia",
    Washington: "Washington",
    "West Virginia": "West Virginia",
    Wisconsin: "Wisconsin",
    Wyoming: "Wyoming",
  },
  france: {
    Ain: "Ain",
    Aisne: "Aisne",
    Allier: "Allier",
    "Alpes-de-Haute-Provence": "Alpes-de-Haute-Provence",
    "Hautes-Alpes": "Hautes-Alpes",
    "Alpes-Maritimes": "Alpes-Maritimes",
    Ardèche: "Ardèche",
    Ardennes: "Ardennes",
    Ariège: "Ariège",
    Aube: "Aube",
    Aude: "Aude",
    Aveyron: "Aveyron",
    "Bouches-du-Rhône": "Bouches-du-Rhône",
    Calvados: "Calvados",
    Cantal: "Cantal",
    Charente: "Charente",
    "Charente-Maritime": "Charente-Maritime",
    Cher: "Cher",
    Corrèze: "Corrèze",
    "Côte-d'Or": "Côte-d'Or",
    "Côtes-d'Armor": "Côtes-d'Armor",
    Creuse: "Creuse",
    Dordogne: "Dordogne",
    Doubs: "Doubs",
    Drôme: "Drôme",
    Eure: "Eure",
    "Eure-et-Loir": "Eure-et-Loir",
    Finistère: "Finistère",
    "Corse-du-Sud": "Corse-du-Sud",
    "Haute-Corse": "Haute-Corse",
    Gard: "Gard",
    "Haute-Garonne": "Haute-Garonne",
    Gers: "Gers",
    Gironde: "Gironde",
    Hérault: "Hérault",
    "Ille-et-Vilaine": "Ille-et-Vilaine",
    Indre: "Indre",
    "Indre-et-Loire": "Indre-et-Loire",
    Isère: "Isère",
    Jura: "Jura",
    Landes: "Landes",
    "Loir-et-Cher": "Loir-et-Cher",
    Loire: "Loire",
    "Haute-Loire": "Haute-Loire",
    "Loire-Atlantique": "Loire-Atlantique",
    Loiret: "Loiret",
    Lot: "Lot",
    "Lot-et-Garonne": "Lot-et-Garonne",
    Lozère: "Lozère",
    "Maine-et-Loire": "Maine-et-Loire",
    Manche: "Manche",
    Marne: "Marne",
    "Haute-Marne": "Haute-Marne",
    Mayenne: "Mayenne",
    "Meurthe-et-Moselle": "Meurthe-et-Moselle",
    Meuse: "Meuse",
    Morbihan: "Morbihan",
    Moselle: "Moselle",
    Nièvre: "Nièvre",
    Nord: "Nord",
    Oise: "Oise",
    Orne: "Orne",
    "Pas-de-Calais": "Pas-de-Calais",
    "Puy-de-Dôme": "Puy-de-Dôme",
    "Pyrénées-Atlantiques": "Pyrénées-Atlantiques",
    "Hautes-Pyrénées": "Hautes-Pyrénées",
    "Pyrénées-Orientales": "Pyrénées-Orientales",
    "Bas-Rhin": "Bas-Rhin",
    "Haut-Rhin": "Haut-Rhin",
    Rhône: "Rhône",
    "Haute-Saône": "Haute-Saône",
    "Saône-et-Loire": "Saône-et-Loire",
    Sarthe: "Sarthe",
    Savoie: "Savoie",
    "Haute-Savoie": "Haute-Savoie",
    Paris: "Paris",
    "Seine-Maritime": "Seine-Maritime",
    "Seine-et-Marne": "Seine-et-Marne",
    Yvelines: "Yvelines",
    "Deux-Sèvres": "Deux-Sèvres",
    Somme: "Somme",
    Tarn: "Tarn",
    "Tarn-et-Garonne": "Tarn-et-Garonne",
    Var: "Var",
    Vaucluse: "Vaucluse",
    Vendée: "Vendée",
    Vienne: "Vienne",
    "Haute-Vienne": "Haute-Vienne",
    Vosges: "Vosges",
    Yonne: "Yonne",
    "Territoire de Belfort": "Territoire de Belfort",
    Essonne: "Essonne",
    "Hauts-de-Seine": "Hauts-de-Seine",
    "Seine-Saint-Denis": "Seine-Saint-Denis",
    "Val-de-Marne": "Val-de-Marne",
    "Val-d'Oise": "Val-d'Oise",
  },
  italy: {
    Agrigento: "Agrigento",
    Alessandria: "Alessandria",
    Ancona: "Ancona",
    Aosta: "Aosta",
    Arezzo: "Arezzo",
    "Ascoli Piceno": "Ascoli Piceno",
    Asti: "Asti",
    Avellino: "Avellino",
    Bari: "Bari",
    "Barletta-Andria-Trani": "Barletta-Andria-Trani",
    Belluno: "Belluno",
    Benevento: "Benevento",
    Bergamo: "Bergamo",
    Biella: "Biella",
    Bologna: "Bologna",
    Bolzano: "Bolzano",
    Brescia: "Brescia",
    Brindisi: "Brindisi",
    Cagliari: "Cagliari",
    Caltanissetta: "Caltanissetta",
    Campobasso: "Campobasso",
    "Carbonia-Iglesias": "Carbonia-Iglesias",
    Caserta: "Caserta",
    Catania: "Catania",
    Catanzaro: "Catanzaro",
    Chieti: "Chieti",
    Como: "Como",
    Cosenza: "Cosenza",
    Cremona: "Cremona",
    Crotone: "Crotone",
    Cuneo: "Cuneo",
    Enna: "Enna",
    Fermo: "Fermo",
    Ferrara: "Ferrara",
    Firenze: "Firenze",
    Foggia: "Foggia",
    "Forlì-Cesena": "Forlì-Cesena",
    Frosinone: "Frosinone",
    Genova: "Genova",
    Gorizia: "Gorizia",
    Grosseto: "Grosseto",
    Imperia: "Imperia",
    Isernia: "Isernia",
    "La Spezia": "La Spezia",
    "L'Aquila": "L'Aquila",
    Latina: "Latina",
    Lecce: "Lecce",
    Lecco: "Lecco",
    Livorno: "Livorno",
    Lodi: "Lodi",
    Lucca: "Lucca",
    Macerata: "Macerata",
    Mantova: "Mantova",
    "Massa-Carrara": "Massa-Carrara",
    Matera: "Matera",
    "Medio Campidano": "Medio Campidano",
    Messina: "Messina",
    Milano: "Milano",
    Modena: "Modena",
    "Monza e della Brianza": "Monza e della Brianza",
    Napoli: "Napoli",
    Novara: "Novara",
    Nuoro: "Nuoro",
    Ogliastra: "Ogliastra",
    "Olbia-Tempio": "Olbia-Tempio",
    Oristano: "Oristano",
    Padova: "Padova",
    Palermo: "Palermo",
    Parma: "Parma",
    Pavia: "Pavia",
    Perugia: "Perugia",
    "Pesaro e Urbino": "Pesaro e Urbino",
    Pescara: "Pescara",
    Piacenza: "Piacenza",
    Pisa: "Pisa",
    Pistoia: "Pistoia",
    Pordenone: "Pordenone",
    Potenza: "Potenza",
    Prato: "Prato",
    Ragusa: "Ragusa",
    Ravenna: "Ravenna",
    "Reggio Calabria": "Reggio Calabria",
    "Reggio Emilia": "Reggio Emilia",
    Rieti: "Rieti",
    Rimini: "Rimini",
    Roma: "Roma",
    Rovigo: "Rovigo",
    Salerno: "Salerno",
    Sassari: "Sassari",
    Savona: "Savona",
    Siena: "Siena",
    Siracusa: "Siracusa",
    Sondrio: "Sondrio",
    Taranto: "Taranto",
    Teramo: "Teramo",
    Terni: "Terni",
    Torino: "Torino",
    Trapani: "Trapani",
    Trento: "Trento",
    Treviso: "Treviso",
    Trieste: "Trieste",
    Udine: "Udine",
    Varese: "Varese",
    Venezia: "Venezia",
    "Verbano-Cusio-Ossola": "Verbano-Cusio-Ossola",
    Vercelli: "Vercelli",
    Verona: "Verona",
    "Vibo Valentia": "Vibo Valentia",
    Vicenza: "Vicenza",
    Viterbo: "Viterbo",
  },
  germany: {
    "Baden-Württemberg": "Baden-Württemberg",
    Bavaria: "Bavaria",
    Berlin: "Berlin",
    Brandenburg: "Brandenburg",
    Bremen: "Bremen",
    Hamburg: "Hamburg",
    Hesse: "Hesse",
    "Lower Saxony": "Lower Saxony",
    "Mecklenburg-Vorpommern": "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia": "North Rhine-Westphalia",
    "Rhineland-Palatinate": "Rhineland-Palatinate",
    Saarland: "Saarland",
    Saxony: "Saxony",
    "Saxony-Anhalt": "Saxony-Anhalt",
    "Schleswig-Holstein": "Schleswig-Holstein",
    Thuringia: "Thuringia",
  },
  argentina: {
    "Buenos Aires": "Buenos Aires",
    Catamarca: "Catamarca",
    Chaco: "Chaco",
    Chubut: "Chubut",
    Córdoba: "Córdoba",
    Corrientes: "Corrientes",
    "Entre Ríos": "Entre Ríos",
    Formosa: "Formosa",
    Jujuy: "Jujuy",
    "La Pampa": "La Pampa",
    "La Rioja": "La Rioja",
    Mendoza: "Mendoza",
    Misiones: "Misiones",
    Neuquén: "Neuquén",
    "Río Negro": "Río Negro",
    Salta: "Salta",
    "San Juan": "San Juan",
    "San Luis": "San Luis",
    "Santa Cruz": "Santa Cruz",
    "Santa Fe": "Santa Fe",
    "Santiago del Estero": "Santiago del Estero",
    "Tierra del Fuego": "Tierra del Fuego",
    Tucumán: "Tucumán",
  },
  canada: {
    Alberta: "Alberta",
    "British Columbia": "British Columbia",
    Manitoba: "Manitoba",
    "New Brunswick": "New Brunswick",
    "Newfoundland and Labrador": "Newfoundland and Labrador",
    "Nova Scotia": "Nova Scotia",
    Ontario: "Ontario",
    "Prince Edward Island": "Prince Edward Island",
    Quebec: "Quebec",
    Saskatchewan: "Saskatchewan",
  },
  mexico: {
    Aguascalientes: "Aguascalientes",
    "Baja California": "Baja California",
    "Baja California Sur": "Baja California Sur",
    Campeche: "Campeche",
    Chiapas: "Chiapas",
    Chihuahua: "Chihuahua",
    "Ciudad de México": "Ciudad de México",
    Coahuila: "Coahuila",
    Colima: "Colima",
    Durango: "Durango",
    Guanajuato: "Guanajuato",
    Guerrero: "Guerrero",
    Hidalgo: "Hidalgo",
    Jalisco: "Jalisco",
    México: "México",
    Michoacán: "Michoacán",
    Morelos: "Morelos",
    Nayarit: "Nayarit",
    "Nuevo León": "Nuevo León",
    Oaxaca: "Oaxaca",
    Puebla: "Puebla",
    Querétaro: "Querétaro",
    "Quintana Roo": "Quintana Roo",
    "San Luis Potosí": "San Luis Potosí",
    Sinaloa: "Sinaloa",
    Sonora: "Sonora",
    Tabasco: "Tabasco",
    Tamaulipas: "Tamaulipas",
    Tlaxcala: "Tlaxcala",
    Veracruz: "Veracruz",
    Yucatán: "Yucatán",
    Zacatecas: "Zacatecas",
  },
  portugal: {
    Aveiro: "Aveiro",
    Beja: "Beja",
    Braga: "Braga",
    Bragança: "Bragança",
    "Castelo Branco": "Castelo Branco",
    Coimbra: "Coimbra",
    Évora: "Évora",
    Faro: "Faro",
    Guarda: "Guarda",
    Leiria: "Leiria",
    Lisboa: "Lisboa",
    Portalegre: "Portalegre",
    Porto: "Porto",
    Santarem: "Santarem",
    Setúbal: "Setúbal",
    "Viana do Castelo": "Viana do Castelo",
    "Vila Real": "Vila Real",
    Viseu: "Viseu",
  },
  uk: {
    England: "England",
    Scotland: "Scotland",
    Wales: "Wales",
    "Northern Ireland": "Northern Ireland",
    "Greater London": "Greater London",
    "North East": "North East",
    "North West": "North West",
    "Yorkshire and the Humber": "Yorkshire and the Humber",
    "East Midlands": "East Midlands",
    "West Midlands": "West Midlands",
    "East of England": "East of England",
    "South East": "South East",
    "South West": "South West",
  },
  ireland: {
    Dublin: "Dublin",
    Cork: "Cork",
    Galway: "Galway",
    Limerick: "Limerick",
    Waterford: "Waterford",
    Wexford: "Wexford",
    Kilkenny: "Kilkenny",
    Mayo: "Mayo",
    Sligo: "Sligo",
    Donegal: "Donegal",
    Leitrim: "Leitrim",
    Roscommon: "Roscommon",
    Longford: "Longford",
    Offaly: "Offaly",
    Westmeath: "Westmeath",
    Louth: "Louth",
    Meath: "Meath",
    Cavan: "Cavan",
    Monaghan: "Monaghan",
  },
  netherlands: {
    Drenthe: "Drenthe",
    Flevoland: "Flevoland",
    Friesland: "Friesland",
    Gelderland: "Gelderland",
    Groningen: "Groningen",
    Limburg: "Limburg",
    "North Brabant": "North Brabant",
    "North Holland": "North Holland",
    Overijssel: "Overijssel",
    "South Holland": "South Holland",
    Utrecht: "Utrecht",
  },
  belgium: {
    Antwerp: "Antwerp",
    "Brabant Wallon": "Brabant Wallon",
    Brussels: "Brussels",
    "East Flanders": "East Flanders",
    "Flemish Brabant": "Flemish Brabant",
    Hainaut: "Hainaut",
    Liege: "Liege",
    Luxembourg: "Luxembourg",
    Namur: "Namur",
    "West Flanders": "West Flanders",
  },
  luxembourg: {
    Diekirch: "Diekirch",
    Grevenmacher: "Grevenmacher",
    "Luxembourg City": "Luxembourg City",
  },
  switzerland: {
    Aargau: "Aargau",
    "Appenzell Ausserrhoden": "Appenzell Ausserrhoden",
    "Appenzell Innerrhoden": "Appenzell Innerrhoden",
    "Basel-Landschaft": "Basel-Landschaft",
    "Basel-Stadt": "Basel-Stadt",
    Bern: "Bern",
    Fribourg: "Fribourg",
    Geneva: "Geneva",
    Glarus: "Glarus",
    Graubünden: "Graubünden",
    Jura: "Jura",
    Luzern: "Luzern",
    Neuchâtel: "Neuchâtel",
    Nidwalden: "Nidwalden",
    Obwalden: "Obwalden",
    Schaffhausen: "Schaffhausen",
    Schwyz: "Schwyz",
    Solothurn: "Solothurn",
    "St. Gallen": "St. Gallen",
    Thurgau: "Thurgau",
    Ticino: "Ticino",
    Uri: "Uri",
    Vaud: "Vaud",
    Valais: "Valais",
    Vaud: "Vaud",
    Zurich: "Zurich",
  },
  austria: {
    Burgenland: "Burgenland",
    Carinthia: "Carinthia",
    "Lower Austria": "Lower Austria",
    Salzburg: "Salzburg",
    Styria: "Styria",
    Tyrol: "Tyrol",
    "Upper Austria": "Upper Austria",
    Vienna: "Vienna",
  },
  poland: {
    Dolnośląskie: "Dolnośląskie",
    "Kujawsko-Pomorskie": "Kujawsko-Pomorskie",
    Lubusz: "Lubusz",
    Łódzkie: "Łódzkie",
    Małopolskie: "Małopolskie",
    Mazowieckie: "Mazowieckie",
    Opolskie: "Opolskie",
    Podkarpackie: "Podkarpackie",
    Podlaskie: "Podlaskie",
    Pomorskie: "Pomorskie",
    Śląskie: "Śląskie",
    Świętokrzyskie: "Świętokrzyskie",
    "Warmińsko-Mazurskie": "Warmińsko-Mazurskie",
    Wielkopolskie: "Wielkopolskie",
    Zachodniopomorskie: "Zachodniopomorskie",
  },
  czech_republic: {
    "Hlavní město Praha": "Hlavní město Praha",
    Jihomoravský: "Jihomoravský",
    Jihočeský: "Jihočeský",
    Karlovarský: "Karlovarský",
    Královéhradecký: "Královéhradecký",
    Liberecký: "Liberecký",
    Moravskoslezský: "Moravskoslezský",
    Olomoucký: "Olomoucký",
    Pardubický: "Pardubický",
    Plzeňský: "Plzeňský",
    Středočeský: "Středočeský",
    Ústecký: "Ústecký",
    Vysočina: "Vysočina",
    Zlin: "Zlin",
  },
  hungary: {
    Budapest: "Budapest",
    "Borsod-Abaúj-Zemplén": "Borsod-Abaúj-Zemplén",
    Baranya: "Baranya",
    Békés: "Békés",
    "Bács-Kiskun": "Bács-Kiskun",
    "Csongrád-Csanád": "Csongrád-Csanád",
    Fejér: "Fejér",
    "Győr-Moson-Sopron": "Győr-Moson-Sopron",
    "Hajdú-Bihar": "Hajdú-Bihar",
    Heves: "Heves",
    "Jász-Nagykun-Szolnok": "Jász-Nagykun-Szolnok",
    "Komárom-Esztergom": "Komárom-Esztergom",
    Nógrád: "Nógrád",
    Pest: "Pest",
    Somogy: "Somogy",
    "Szabolcs-Szatmár-Bereg": "Szabolcs-Szatmár-Bereg",
    Tolna: "Tolna",
    Vas: "Vas",
    Veszprém: "Veszprém",
    Zala: "Zala",
  },
  slovakia: {
    Bratislava: "Bratislava",
    Trnava: "Trnava",
    Nitra: "Nitra",
    Trenčín: "Trenčín",
    Žilina: "Žilina",
    Prešov: "Prešov",
    Košice: "Košice",
  },
  slovenia: {
    Pomurska: "Pomurska",
    Podravska: "Podravska",
    Posavska: "Posavska",
    Osrednjeslovenska: "Osrednjeslovenska",
    Gorenjska: "Gorenjska",
    "Primorsko-notranjska": "Primorsko-notranjska",
    Koroška: "Koroška",
    Zasavska: "Zasavska",
    Goriška: "Goriška",
  },
  croatia: {
    Zagreb: "Zagreb",
    "Dubrovnik-Neretva": "Dubrovnik-Neretva",
    "Osijek-Baranja": "Osijek-Baranja",
    Pazin: "Pazin",
    Poreč: "Poreč",
    Pula: "Pula",
    "Šibenik-Knin": "Šibenik-Knin",
    "Split-Dalmatia": "Split-Dalmatia",
    Varaždin: "Varaždin",
    "Virovitica-Podravina": "Virovitica-Podravina",
    Zadar: "Zadar",
    "Zagreb City": "Zagreb City",
  },
  serbia: {
    Belgrade: "Belgrade",
    Vojvodina: "Vojvodina",
    Šumadija: "Šumadija",
    "Southern and Eastern Serbia": "Southern and Eastern Serbia",
  },
  bosnia: {
    "Federation of Bosnia and Herzegovina":
      "Federation of Bosnia and Herzegovina",
    "Republika Srpska": "Republika Srpska",
    "Brčko District": "Brčko District",
  },
  greece: {
    Attica: "Attica",
    "Central Greece": "Central Greece",
    "Central Macedonia": "Central Macedonia",
    Crete: "Crete",
    "Eastern Macedonia and Thrace": "Eastern Macedonia and Thrace",
    Epirus: "Epirus",
    "Ionian Islands": "Ionian Islands",
    "Northern Aegean": "Northern Aegean",
    Peloponnese: "Peloponnese",
    "Southern Aegean": "Southern Aegean",
    "Western Greece": "Western Greece",
    "Western Macedonia": "Western Macedonia",
  },
  cyprus: {
    Nicosia: "Nicosia",
    Famagusta: "Famagusta",
    Larnaca: "Larnaca",
    Limassol: "Limassol",
    Paphos: "Paphos",
  },
  bulgaria: {
    Blagoevgrad: "Blagoevgrad",
    Burgas: "Burgas",
    Dobrich: "Dobrich",
    Gabrovo: "Gabrovo",
    Haskovo: "Haskovo",
    Kardzhali: "Kardzhali",
    Kyustendil: "Kyustendil",
    Lovech: "Lovech",
    Montana: "Montana",
    Pazardzhik: "Pazardzhik",
    Pernik: "Pernik",
    Plovdiv: "Plovdiv",
    Ruse: "Ruse",
    Shumen: "Shumen",
    Silistra: "Silistra",
    Sliven: "Sliven",
    Smolyan: "Smolyan",
    "Sofia City": "Sofia City",
    "Sofia Province": "Sofia Province",
    Varna: "Varna",
    Viden: "Viden",
    Vratsa: "Vratsa",
  },
  romania: {
    București: "București",
    Bacău: "Bacău",
    Bihor: "Bihor",
    "Bistrița-Năsăud": "Bistrița-Năsăud",
    Brașov: "Brașov",
    Brăila: "Brăila",
    Buzău: "Buzău",
    Călărași: "Călărași",
    Cluj: "Cluj",
    Constanța: "Constanța",
    Dâmbovița: "Dâmbovița",
    Dolj: "Dolj",
    Gorj: "Gorj",
    Harghita: "Harghita",
    Hunedoara: "Hunedoara",
    Ialomița: "Ialomița",
    Iasi: "Iasi",
    Ilfov: "Ilfov",
    Maramureș: "Maramureș",
    Mehedinți: "Mehedinți",
    Mureș: "Mureș",
    Olt: "Olt",
    Prahova: "Prahova",
    "Satu Mare": "Satu Mare",
    Sălaj: "Sălaj",
    Sibiu: "Sibiu",
    Teleorman: "Teleorman",
    Timiș: "Timiș",
    Vâlcea: "Vâlcea",
    Vrancea: "Vrancea",
  },
  albania: {
    Berat: "Berat",
    Dibër: "Dibër",
    Durrës: "Durrës",
    Shkodër: "Shkodër",
    Fier: "Fier",
    Korçë: "Korçë",
    Vlorë: "Vlorë",
  },
  denmark: {
    "Capital Region": "Capital Region",
    "Central Denmark": "Central Denmark",
    "North Denmark": "North Denmark",
    "South Denmark": "South Denmark",
    Zealand: "Zealand",
  },
  sweden: {
    Stockholm: "Stockholm",
    Uppsala: "Uppsala",
    Södermanland: "Södermanland",
    Östergötland: "Östergötland",
    Jönköping: "Jönköping",
    Kronoberg: "Kronoberg",
    Kalmar: "Kalmar",
    Gotland: "Gotland",
    Blekinge: "Blekinge",
    Skåne: "Skåne",
    Halland: "Halland",
    "Västra Götaland": "Västra Götaland",
    Värmland: "Värmland",
    Dalarna: "Dalarna",
    Gävleborg: "Gävleborg",
    Västernorrland: "Västernorrland",
    Jämtland: "Jämtland",
    Västerbotten: "Västerbotten",
    Norrbotten: "Norrbotten",
  },
  norway: {
    Oslo: "Oslo",
    Viken: "Viken",
    Innlandet: "Innlandet",
    "Vestfold og Telemark": "Vestfold og Telemark",
    Agder: "Agder",
    Rogaland: "Rogaland",
    Vestland: "Vestland",
    Trøndelag: "Trøndelag",
    Nordland: "Nordland",
    "Troms og Finnmark": "Troms og Finnmark",
  },
  finland: {
    Åland: "Åland",
    "Southern Finland": "Southern Finland",
    "Western Finland": "Western Finland",
    "Northern Finland": "Northern Finland",
  },
  iceland: {
    "Capital Region": "Capital Region",
    "Southern Peninsula": "Southern Peninsula",
    "Western Region": "Western Region",
    Northeast: "Northeast",
    Northwest: "Northwest",
    East: "East",
  },
  estonia: {
    Harju: "Harju",
    Hiiu: "Hiiu",
    "Ida-Viru": "Ida-Viru",
    Jõgeva: "Jõgeva",
    Järva: "Järva",
    Lääne: "Lääne",
    "Lääne-Viru": "Lääne-Viru",
    Põlva: "Põlva",
    Pärnu: "Pärnu",
    Rapla: "Rapla",
    Tartu: "Tartu",
    Valga: "Valga",
    Viljandi: "Viljandi",
  },
  latvia: {
    Aizkraukle: "Aizkraukle",
    Alūksne: "Alūksne",
    Balvi: "Balvi",
    Bauska: "Bauska",
    Cēsis: "Cēsis",
    Dobele: "Dobele",
    Jelgava: "Jelgava",
    Jūrmala: "Jūrmala",
    Liepāja: "Liepāja",
    Riga: "Riga",
    Rūjiena: "Rūjiena",
    Ventspils: "Ventspils",
  },
  lithuania: {
    Alytus: "Alytus",
    Kaunas: "Kaunas",
    Klaipėda: "Klaipėda",
    Marijampolė: "Marijampolė",
    Panevėžys: "Panevėžys",
    Šiauliai: "Šiauliai",
    Vilnius: "Vilnius",
  },
  ukraine: {
    Cherkasy: "Cherkasy",
    Chernihiv: "Chernihiv",
    Chernivtsi: "Chernivtsi",
    Dnipropetrovsk: "Dnipropetrovsk",
    Donetsk: "Donetsk",
    "Ivano-Frankivsk": "Ivano-Frankivsk",
    Kharkiv: "Kharkiv",
    Kherson: "Kherson",
    Khmelnitsky: "Khmelnitsky",
    Kiev: "Kiev",
    Kirovohrad: "Kirovohrad",
    Luhansk: "Luhansk",
    Lviv: "Lviv",
    Mykolaiv: "Mykolaiv",
    Odessa: "Odessa",
    Poltava: "Poltava",
    Rivne: "Rivne",
    Sumy: "Sumy",
    Ternopil: "Ternopil",
    Vinnytsia: "Vinnytsia",
    Zakarpattia: "Zakarpattia",
    Zaporizhia: "Zaporizhia",
    Zhytomyr: "Zhytomyr",
  },
  moldova: {
    Chișinău: "Chișinău",
    Bălți: "Bălți",
    Bender: "Bender",
    Dubăsari: "Dubăsari",
    Edineț: "Edineț",
    Nisporeni: "Nisporeni",
    Orhei: "Orhei",
    Soroca: "Soroca",
    "Ștefan Vodă": "Ștefan Vodă",
    Taraclia: "Taraclia",
    Ungheni: "Ungheni",
  },
  georgia: {
    Tbilisi: "Tbilisi",
    Adjara: "Adjara",
    Imereti: "Imereti",
    "Kvemo Kartli": "Kvemo Kartli",
    "Mtskheta-Mtianeti": "Mtskheta-Mtianeti",
    "Racha-Lechkhumi and Kvemo Svaneti": "Racha-Lechkhumi and Kvemo Svaneti",
    "Samegrelo-Zemo Svaneti": "Samegrelo-Zemo Svaneti",
    "Shida Kartli": "Shida Kartli",
  },
  armenia: {
    Yerevan: "Yerevan",
    Ararat: "Ararat",
    Armavir: "Armavir",
    Gegharkunik: "Gegharkunik",
    Kotayk: "Kotayk",
    Lori: "Lori",
    Shirak: "Shirak",
    "Vayots Dzor": "Vayots Dzor",
    Syunik: "Syunik",
  },
  liechtenstein: {
    Vaduz: "Vaduz",
  },
  monaco: {
    Monaco: "Monaco",
  },
  andorra: {
    "Andorra la Vella": "Andorra la Vella",
    "Escaldes-Engordany": "Escaldes-Engordany",
    Encamp: "Encamp",
    Ordino: "Ordino",
    "Sant Julià de Lòria": "Sant Julià de Lòria",
  },
  malta: {
    Valletta: "Valletta",
    Sliema: "Sliema",
    Birkirkara: "Birkirkara",
    Mosta: "Mosta",
    Qormi: "Qormi",
    Senglea: "Senglea",
    Cottonera: "Cottonera",
  },
  "san-marino": {
    "San Marino": "San Marino",
  },
  "vatican-city": {
    "Vatican City": "Vatican City",
  },
  kosovo: {
    Pristina: "Pristina",
  },
  montenegro: {
    Podgorica: "Podgorica",
    Nikšić: "Nikšić",
    "Herceg Novi": "Herceg Novi",
    "Bijelo Polje": "Bijelo Polje",
  },
  "bosnia-and-herzegovina": {
    Sarajevo: "Sarajevo",
    "Banja Luka": "Banja Luka",
    Tuzla: "Tuzla",
    Zenica: "Zenica",
    Bijeljina: "Bijeljina",
    Brčko: "Brčko",
  },
  brazil: {
    Acre: "Acre",
    Alagoas: "Alagoas",
    Amazonas: "Amazonas",
    Bahia: "Bahia",
    Ceará: "Ceará",
    "Distrito Federal": "Distrito Federal",
    "Espírito Santo": "Espírito Santo",
    Goiás: "Goiás",
    Maranhão: "Maranhão",
    "Mato Grosso": "Mato Grosso",
    "Mato Grosso do Sul": "Mato Grosso do Sul",
    "Minas Gerais": "Minas Gerais",
    Pará: "Pará",
    Paraíba: "Paraíba",
    Paraná: "Paraná",
    Pernambuco: "Pernambuco",
    Piauí: "Piauí",
    "Rio de Janeiro": "Rio de Janeiro",
    "Rio Grande do Norte": "Rio Grande do Norte",
    "Rio Grande do Sul": "Rio Grande do Sul",
    Rondônia: "Rondônia",
    Roraima: "Roraima",
    "Santa Catarina": "Santa Catarina",
    "São Paulo": "São Paulo",
    Sergipe: "Sergipe",
    Tocantins: "Tocantins",
  },
  chile: {
    "Arica y Parinacota": "Arica y Parinacota",
    Tarapacá: "Tarapacá",
    Antofagasta: "Antofagasta",
    Atacama: "Atacama",
    Coquimbo: "Coquimbo",
    Valparaíso: "Valparaíso",
    "Metropolitana de Santiago": "Metropolitana de Santiago",
    "O Higgins": "O Higgins",
    Maule: "Maule",
    Ñuble: "Ñuble",
    Biobío: "Biobío",
    "La Araucanía": "La Araucanía",
    "Los Ríos": "Los Ríos",
    "Los Lagos": "Los Lagos",
    Aysén: "Aysén",
    "Magallanes y de la Antártica Chilena":
      "Magallanes y de la Antártica Chilena",
  },
  colombia: {
    Amazonas: "Amazonas",
    Antioquia: "Antioquia",
    Arauca: "Arauca",
    Atlántico: "Atlántico",
    "Bogotá D.C.": "Bogotá D.C.",
    Bolívar: "Bolívar",
    Boyacá: "Boyacá",
    Caldas: "Caldas",
    Caquetá: "Caquetá",
    Casanare: "Casanare",
    Cauca: "Cauca",
    Cesar: "Cesar",
    Chocó: "Chocó",
    Córdoba: "Córdoba",
    Cundinamarca: "Cundinamarca",
    Guainía: "Guainía",
    Guaviare: "Guaviare",
    Guajira: "Guajira",
    Huila: "Huila",
    "La Guajira": "La Guajira",
    Magdalena: "Magdalena",
    Meta: "Meta",
    Nariño: "Nariño",
    "Norte de Santander": "Norte de Santander",
    Putumayo: "Putumayo",
    Quindío: "Quindío",
    Risaralda: "Risaralda",
    "San Andrés y Providencia": "San Andrés y Providencia",
    Santander: "Santander",
    Sucre: "Sucre",
    Tolima: "Tolima",
    "Valle del Cauca": "Valle del Cauca",
    Vaupés: "Vaupés",
    Vichada: "Vichada",
  },
  venezuela: {
    Amazonas: "Amazonas",
    Anzoátegui: "Anzoátegui",
    Apure: "Apure",
    Aragua: "Aragua",
    Barinas: "Barinas",
    Bolívar: "Bolívar",
    Carabobo: "Carabobo",
    Cojedes: "Cojedes",
    "Delta Amacuro": "Delta Amacuro",
    Falcón: "Falcón",
    Guárico: "Guárico",
    Lara: "Lara",
    Mérida: "Mérida",
    Miranda: "Miranda",
    Monagas: "Monagas",
    "Nueva Esparta": "Nueva Esparta",
    Portuguesa: "Portuguesa",
    Sucre: "Sucre",
    Táchira: "Táchira",
    Trujillo: "Trujillo",
    Vargas: "Vargas",
    Yaracuy: "Yaracuy",
    Zulia: "Zulia",
  },
  peru: {
    Amazonas: "Amazonas",
    Ancash: "Ancash",
    Apurímac: "Apurímac",
    Arequipa: "Arequipa",
    Ayacucho: "Ayacucho",
    Cajamarca: "Cajamarca",
    Callao: "Callao",
    Cusco: "Cusco",
    Huancavelica: "Huancavelica",
    Huánuco: "Huánuco",
    Ica: "Ica",
    Junín: "Junín",
    "La Libertad": "La Libertad",
    Lambayeque: "Lambayeque",
    Lima: "Lima",
    Loreto: "Loreto",
    "Madre de Dios": "Madre de Dios",
    Moquegua: "Moquegua",
    Pasco: "Pasco",
    Piura: "Piura",
    Puno: "Puno",
    "San Martín": "San Martín",
    Tacna: "Tacna",
    Tumbes: "Tumbes",
    Ucayali: "Ucayali",
  },
  uruguay: {
    Artigas: "Artigas",
    Canelones: "Canelones",
    "Cerro Largo": "Cerro Largo",
    Colonia: "Colonia",
    Durazno: "Durazno",
    "El Dorado": "El Dorado",
    Flores: "Flores",
    Florida: "Florida",
    Lavalleja: "Lavalleja",
    Maldonado: "Maldonado",
    Montevideo: "Montevideo",
    Paysandú: "Paysandú",
    "Río Negro": "Río Negro",
    Rivera: "Rivera",
    Rocha: "Rocha",
    Salto: "Salto",
    "San José": "San José",
    "San Pedro": "San Pedro",
    "Santa Bárbara": "Santa Bárbara",
    "Santa Tecla": "Santa Tecla",
    Tacuarembó: "Tacuarembó",
    "Treinta y Tres": "Treinta y Tres",
  },
  paraguay: {
    "Alto Paraná": "Alto Paraná",
    Amambay: "Amambay",
    Boquerón: "Boquerón",
    Caaguazú: "Caaguazú",
    Caazapá: "Caazapá",
    Central: "Central",
    Concepción: "Concepción",
    Cordillera: "Cordillera",
    Guairá: "Guairá",
    Itapúa: "Itapúa",
    Misiones: "Misiones",
    Ñeembucú: "Ñeembucú",
    Paraguarí: "Paraguarí",
    "Presidente Hayes": "Presidente Hayes",
    "San Pedro": "San Pedro",
  },
  ecuador: {
    Azuay: "Azuay",
    Bolívar: "Bolívar",
    Cañar: "Cañar",
    Carchi: "Carchi",
    Chimborazo: "Chimborazo",
    Cotopaxi: "Cotopaxi",
    "El Oro": "El Oro",
    Esmeraldas: "Esmeraldas",
    Galápagos: "Galápagos",
    Guayas: "Guayas",
    Imbabura: "Imbabura",
    Loja: "Loja",
    "Los Ríos": "Los Ríos",
    Manabí: "Manabí",
    "Morona Santiago": "Morona Santiago",
    Napo: "Napo",
    Orellana: "Orellana",
    Pastaza: "Pastaza",
    Pichincha: "Pichincha",
    "Santa Elena": "Santa Elena",
    "Santo Domingo de los Tsáchilas": "Santo Domingo de los Tsáchilas",
    Sucumbíos: "Sucumbíos",
    Tungurahua: "Tungurahua",
    "Zamora-Chinchipe": "Zamora-Chinchipe",
  },
  bolivia: {
    Chuquisaca: "Chuquisaca",
    Cochabamba: "Cochabamba",
    "Colcha K": "Colcha K",
    "La Paz": "La Paz",
    Oruro: "Oruro",
    Pando: "Pando",
    Potosí: "Potosí",
    "Santa Cruz": "Santa Cruz",
    Tarija: "Tarija",
  },
  costa_rica: {
    "San José": "San José",
    Alajuela: "Alajuela",
    Cartago: "Cartago",
    Heredia: "Heredia",
    Guanacaste: "Guanacaste",
    Puntarenas: "Puntarenas",
    Limón: "Limón",
  },
  panama: {
    "Bocas del Toro": "Bocas del Toro",
    Chiriquí: "Chiriquí",
    Coclé: "Coclé",
    Colón: "Colón",
    Darien: "Darien",
    Herrera: "Herrera",
    "Los Santos": "Los Santos",
    Panamá: "Panamá",
    Veraguas: "Veraguas",
    "Guna Yala": "Guna Yala",
    "Ngäbe-Buglé": "Ngäbe-Buglé",
  },
  guatemala: {
    "Alta Verapaz": "Alta Verapaz",
    "Baja Verapaz": "Baja Verapaz",
    Chimaltenango: "Chimaltenango",
    Chiquimula: "Chiquimula",
    "El Progreso": "El Progreso",
    Escuintla: "Escuintla",
    Guatemala: "Guatemala",
    Huehuetenango: "Huehuetenango",
    Izabal: "Izabal",
    Jalapa: "Jalapa",
    Jutiapa: "Jutiapa",
    Petén: "Petén",
    Quetzaltenango: "Quetzaltenango",
    Quiché: "Quiché",
    Retalhuleu: "Retalhuleu",
    Sacatepéquez: "Sacatepéquez",
    "San Marcos": "San Marcos",
    "Santa Rosa": "Santa Rosa",
    Solalá: "Solalá",
    Totonicapán: "Totonicapán",
    Zacapa: "Zacapa",
  },
  honduras: {
    Atlántida: "Atlántida",
    Choluteca: "Choluteca",
    Colón: "Colón",
    Comayagua: "Comayagua",
    Cortés: "Cortés",
    "El Paraíso": "El Paraíso",
    "Francisco Morazán": "Francisco Morazán",
    "Gracias a Dios": "Gracias a Dios",
    "Islas de la Bahía": "Islas de la Bahía",
    "La Paz": "La Paz",
    Lempira: "Lempira",
    Ocotepeque: "Ocotepeque",
    Olancho: "Olancho",
    "Santa Bárbara": "Santa Bárbara",
    Valle: "Valle",
    Yoro: "Yoro",
  },
  el_salvador: {
    Ahuachapán: "Ahuachapán",
    Cabañas: "Cabañas",
    Chalatenango: "Chalatenango",
    Cuscatlán: "Cuscatlán",
    "La Libertad": "La Libertad",
    "La Paz": "La Paz",
    "La Unión": "La Unión",
    Morazán: "Morazán",
    "San Miguel": "San Miguel",
    "San Salvador": "San Salvador",
    "San Vicente": "San Vicente",
    "Santa Ana": "Santa Ana",
    Sonsonate: "Sonsonate",
    Usulután: "Usulután",
  },
  nicaragua: {
    Boaco: "Boaco",
    Carazo: "Carazo",
    Chinandega: "Chinandega",
    Chontales: "Chontales",
    Granada: "Granada",
    Jinotega: "Jinotega",
    León: "León",
    Madriz: "Madriz",
    Managua: "Managua",
    Masaya: "Masaya",
    Matagalpa: "Matagalpa",
    "Nueva Segovia": "Nueva Segovia",
    Rivas: "Rivas",
    "Rio San Juan": "Rio San Juan",
  },
  cuba: {
    "Pinar del Río": "Pinar del Río",
    Artemisa: "Artemisa",
    Havana: "Havana",
    Mayabeque: "Mayabeque",
    Matanzas: "Matanzas",
    Cienfuegos: "Cienfuegos",
    "Villa Clara": "Villa Clara",
    "Sancti Spíritus": "Sancti Spíritus",
    "Ciego de Ávila": "Ciego de Ávila",
    Camagüey: "Camagüey",
    "Las Tunas": "Las Tunas",
    Holguín: "Holguín",
    Guantánamo: "Guantánamo",
  },
  dominican_republic: {
    Azua: "Azua",
    Baoruco: "Baoruco",
    Barahona: "Barahona",
    Dajabón: "Dajabón",
    "Distrito Nacional": "Distrito Nacional",
    Duarte: "Duarte",
    "Elías Piña": "Elías Piña",
    "El Seibo": "El Seibo",
    Espaillat: "Espaillat",
    "Hato Mayor": "Hato Mayor",
    "La Altagracia": "La Altagracia",
    "La Romana": "La Romana",
    "La Vega": "La Vega",
    "María Trinidad Sánchez": "María Trinidad Sánchez",
    "Monte Cristi": "Monte Cristi",
    Pedernales: "Pedernales",
    Peravia: "Peravia",
    "Puerto Plata": "Puerto Plata",
    Samaná: "Samaná",
    "San Cristóbal": "San Cristóbal",
    "San José de Ocoa": "San José de Ocoa",
    "San Juan": "San Juan",
    "San Pedro de Macorís": "San Pedro de Macorís",
    "Sánchez Ramírez": "Sánchez Ramírez",
    Santiago: "Santiago",
    "Santiago Rodríguez": "Santiago Rodríguez",
    Valverde: "Valverde",
  },
  puerto_rico: {
    Aguada: "Aguada",
    Aguadilla: "Aguadilla",
    "Aguas Buenas": "Aguas Buenas",
    Aibonito: "Aibonito",
    Arecibo: "Arecibo",
    Arroyo: "Arroyo",
    Bayamón: "Bayamón",
    Caguas: "Caguas",
    Camuy: "Camuy",
    Canóvanas: "Canóvanas",
    Carolina: "Carolina",
    Cayey: "Cayey",
    Ceiba: "Ceiba",
    Ciales: "Ciales",
    Cidra: "Cidra",
    Coamo: "Coamo",
    Comerío: "Comerío",
    Fajardo: "Fajardo",
    Florida: "Florida",
    Guánica: "Guánica",
    Guayama: "Guayama",
    Guayanilla: "Guayanilla",
    Guaynabo: "Guaynabo",
    Hatillo: "Hatillo",
    Hormigueros: "Hormigueros",
    Humacao: "Humacao",
    Isabela: "Isabela",
    Jayuya: "Jayuya",
    "Juana Díaz": "Juana Díaz",
    Juncos: "Juncos",
    Lajas: "Lajas",
    Lares: "Lares",
    "Las Marías": "Las Marías",
    "Las Piedras": "Las Piedras",
    Loíza: "Loíza",
    Luquillo: "Luquillo",
    Manatí: "Manatí",
    Maricao: "Maricao",
    Maunabo: "Maunabo",
    Morovis: "Morovis",
    Naguabo: "Naguabo",
    Naranjito: "Naranjito",
    Orocovis: "Orocovis",
    Paguate: "Paguate",
    Peñuelas: "Peñuelas",
    Ponce: "Ponce",
    Rincón: "Rincón",
    "Río Grande": "Río Grande",
    "Sabana Grande": "Sabana Grande",
    Salinas: "Salinas",
    "San Germán": "San Germán",
    "San Juan": "San Juan",
    "San Lorenzo": "San Lorenzo",
    "San Sebastián": "San Sebastián",
    "Santa Isabel": "Santa Isabel",
    "Toa Alta": "Toa Alta",
    "Toa Baja": "Toa Baja",
    "Trujillo Alto": "Trujillo Alto",
    Vieques: "Vieques",
    Villalba: "Villalba",
    Yabucoa: "Yabucoa",
    Yauco: "Yauco",
  },
  china: {
    Anhui: "Anhui",
    Beijing: "Beijing",
    Chongqing: "Chongqing",
    Fujian: "Fujian",
    Gansu: "Gansu",
    Guangdong: "Guangdong",
    Guangxi: "Guangxi",
    Guizhou: "Guizhou",
    Hainan: "Hainan",
    Hebei: "Hebei",
    Heilongjiang: "Heilongjiang",
    Henan: "Henan",
    Hubei: "Hubei",
    Hunan: "Hunan",
    "Inner Mongolia": "Inner Mongolia",
    Jiangsu: "Jiangsu",
    Jiangxi: "Jiangxi",
    Jilin: "Jilin",
    Liaoning: "Liaoning",
    Ningxia: "Ningxia",
    Qinghai: "Qinghai",
    Shaanxi: "Shaanxi",
    Shandong: "Shandong",
    Shanghai: "Shanghai",
    Shanxi: "Shanxi",
    Sichuan: "Sichuan",
    Tianjin: "Tianjin",
    Tibet: "Tibet",
    Xinjiang: "Xinjiang",
    Yunnan: "Yunnan",
    Zhejiang: "Zhejiang",
  },
  japan: {
    Hokkaido: "Hokkaido",
    Aomori: "Aomori",
    Iwate: "Iwate",
    Miyagi: "Miyagi",
    Akita: "Akita",
    Yamagata: "Yamagata",
    Fukushima: "Fukushima",
    Ibaraki: "Ibaraki",
    Tochigi: "Tochigi",
    Gunma: "Gunma",
    Saitama: "Saitama",
    Chiba: "Chiba",
    Tokyo: "Tokyo",
    Kanagawa: "Kanagawa",
    Niigata: "Niigata",
    Toyama: "Toyama",
    Ishikawa: "Ishikawa",
    Fukui: "Fukui",
    Yamanashi: "Yamanashi",
    Nagano: "Nagano",
    Gifu: "Gifu",
    Shizuoka: "Shizuoka",
    Aichi: "Aichi",
    Mie: "Mie",
    Shiga: "Shiga",
    Kyoto: "Kyoto",
    Osaka: "Osaka",
    Hyogo: "Hyogo",
    Nara: "Nara",
    Wakayama: "Wakayama",
    Tottori: "Tottori",
    Shimane: "Shimane",
    Okayama: "Okayama",
    Hiroshima: "Hiroshima",
    Yamaguchi: "Yamaguchi",
    Tokushima: "Tokushima",
    Kagawa: "Kagawa",
    Ehime: "Ehime",
    Kochi: "Kochi",
    Fukuoka: "Fukuoka",
    Saga: "Saga",
    Nagasaki: "Nagasaki",
    Kumamoto: "Kumamoto",
    Oita: "Oita",
    Miyazaki: "Miyazaki",
    Kagoshima: "Kagoshima",
    Okinawa: "Okinawa",
  },
  south_korea: {
    Seoul: "Seoul",
    Busan: "Busan",
    Daegu: "Daegu",
    Incheon: "Incheon",
    Gwangju: "Gwangju",
    Daejeon: "Daejeon",
    Ulsan: "Ulsan",
    Sejong: "Sejong",
    "Gyeonggi-do": "Gyeonggi-do",
    "Gangwon-do": "Gangwon-do",
    "Chungcheongbuk-do": "Chungcheongbuk-do",
    "Chungcheongnam-do": "Chungcheongnam-do",
    "Jeollabuk-do": "Jeollabuk-do",
    "Jeollanam-do": "Jeollanam-do",
    "Gyeongsangbuk-do": "Gyeongsangbuk-do",
    "Gyeongsangnam-do": "Gyeongsangnam-do",
    Jeju: "Jeju",
  },
  india: {
    "Andhra Pradesh": "Andhra Pradesh",
    "Arunachal Pradesh": "Arunachal Pradesh",
    Assam: "Assam",
    Bihar: "Bihar",
    Chhattisgarh: "Chhattisgarh",
    Goa: "Goa",
    Gujarat: "Gujarat",
    Haryana: "Haryana",
    "Himachal Pradesh": "Himachal Pradesh",
    Jharkhand: "Jharkhand",
    Karnataka: "Karnataka",
    Kerala: "Kerala",
    "Madhya Pradesh": "Madhya Pradesh",
    Maharashtra: "Maharashtra",
    Manipur: "Manipur",
    Meghalaya: "Meghalaya",
    Mizoram: "Mizoram",
    Nagaland: "Nagaland",
    Odisha: "Odisha",
    Punjab: "Punjab",
    Rajasthan: "Rajasthan",
    Sikkim: "Sikkim",
    "Tamil Nadu": "Tamil Nadu",
    Telangana: "Telangana",
    Tripura: "Tripura",
    "Uttar Pradesh": "Uttar Pradesh",
    Uttarakhand: "Uttarakhand",
    "West Bengal": "West Bengal",
    Delhi: "Delhi",
    Puducherry: "Puducherry",
  },
  indonesia: {
    Aceh: "Aceh",
    Bali: "Bali",
    Banten: "Banten",
    Bengkulu: "Bengkulu",
    "Central Java": "Central Java",
    "Central Kalimantan": "Central Kalimantan",
    "Central Sulawesi": "Central Sulawesi",
    "East Java": "East Java",
    "East Kalimantan": "East Kalimantan",
    "East Nusa Tenggara": "East Nusa Tenggara",
    Gorontalo: "Gorontalo",
    Jakarta: "Jakarta",
    Jambi: "Jambi",
    Lampung: "Lampung",
    Maluku: "Maluku",
    "North Kalimantan": "North Kalimantan",
    "North Maluku": "North Maluku",
    "North Sulawesi": "North Sulawesi",
    "North Sumatra": "North Sumatra",
    Papua: "Papua",
    Riau: "Riau",
    "South Kalimantan": "South Kalimantan",
    "South Sulawesi": "South Sulawesi",
    "South Sumatra": "South Sumatra",
    "West Java": "West Java",
    "West Kalimantan": "West Kalimantan",
    "West Nusa Tenggara": "West Nusa Tenggara",
    "West Papua": "West Papua",
    "West Sulawesi": "West Sulawesi",
    "West Sumatra": "West Sumatra",
    Yogyakarta: "Yogyakarta",
  },
  vietnam: {
    "An Giang": "An Giang",
    "Bà Rịa-Vũng Tàu": "Bà Rịa-Vũng Tàu",
    "Bắc Giang": "Bắc Giang",
    "Bắc Kạn": "Bắc Kạn",
    "Bạc Liêu": "Bạc Liêu",
    "Bắc Ninh": "Bắc Ninh",
    "Bến Tre": "Bến Tre",
    "Bình Định": "Bình Định",
    "Bình Dương": "Bình Dương",
    "Bình Phước": "Bình Phước",
    "Bình Thuận": "Bình Thuận",
    "Cà Mau": "Cà Mau",
    "Cần Thơ": "Cần Thơ",
    "Đà Nẵng": "Đà Nẵng",
    "Đắk Lắk": "Đắk Lắk",
    "Đắk Nông": "Đắk Nông",
    "Điện Biên": "Điện Biên",
    "Đồng Nai": "Đồng Nai",
    "Đồng Tháp": "Đồng Tháp",
    "Gia Lai": "Gia Lai",
    "Hà Giang": "Hà Giang",
    "Hà Nam": "Hà Nam",
    "Hà Nội": "Hà Nội",
    "Hà Tĩnh": "Hà Tĩnh",
    "Hải Dương": "Hải Dương",
    "Hải Phòng": "Hải Phòng",
    "Hậu Giang": "Hậu Giang",
    "Hồ Chí Minh": "Hồ Chí Minh",
    "Hòa Bình": "Hòa Bình",
    "Hưng Yên": "Hưng Yên",
    "Khánh Hòa": "Khánh Hòa",
    "Kiên Giang": "Kiên Giang",
    "Kon Tum": "Kon Tum",
    "Lai Châu": "Lai Châu",
    "Lâm Đồng": "Lâm Đồng",
    "Lạng Sơn": "Lạng Sơn",
    "Lào Cai": "Lào Cai",
    "Long An": "Long An",
    "Nam Định": "Nam Định",
    "Nghệ An": "Nghệ An",
    "Ninh Bình": "Ninh Bình",
    "Ninh Thuận": "Ninh Thuận",
    "Phú Thọ": "Phú Thọ",
    "Phú Yên": "Phú Yên",
    "Quảng Bình": "Quảng Bình",
    "Quảng Nam": "Quảng Nam",
    "Quảng Ngãi": "Quảng Ngãi",
    "Quảng Ninh": "Quảng Ninh",
    "Quảng Trị": "Quảng Trị",
    "Sóc Trăng": "Sóc Trăng",
    "Sơn La": "Sơn La",
    "Tây Ninh": "Tây Ninh",
    "Thái Bình": "Thái Bình",
    "Thái Nguyên": "Thái Nguyên",
    "Thanh Hóa": "Thanh Hóa",
    "Thừa Thiên-Huế": "Thừa Thiên-Huế",
    "Tiền Giang": "Tiền Giang",
    "Trà Vinh": "Trà Vinh",
    "Tuyên Quang": "Tuyên Quang",
    "Vĩnh Long": "Vĩnh Long",
    "Vĩnh Phúc": "Vĩnh Phúc",
    "Yên Bái": "Yên Bái",
  },
  philippines: {
    Abra: "Abra",
    "Agusan del Norte": "Agusan del Norte",
    "Agusan del Sur": "Agusan del Sur",
    Aklan: "Aklan",
    Albay: "Albay",
    Antique: "Antique",
    Apayao: "Apayao",
    Aurora: "Aurora",
    Basilan: "Basilan",
    Bataan: "Bataan",
    Batanes: "Batanes",
    Batangas: "Batangas",
    Benguet: "Benguet",
    Biliran: "Biliran",
    Bohol: "Bohol",
    Bukidnon: "Bukidnon",
    Bulacan: "Bulacan",
    Cagayan: "Cagayan",
    "Camarines Norte": "Camarines Norte",
    "Camarines Sur": "Camarines Sur",
    Camiguin: "Camiguin",
    Capiz: "Capiz",
    Catanduanes: "Catanduanes",
    Cavite: "Cavite",
    Cebu: "Cebu",
    "Compostela Valley": "Compostela Valley",
    Cotabato: "Cotabato",
    "Davao del Norte": "Davao del Norte",
    "Davao del Sur": "Davao del Sur",
    "Davao Occidental": "Davao Occidental",
    "Davao Oriental": "Davao Oriental",
    "Dinagat Islands": "Dinagat Islands",
    "Eastern Samar": "Eastern Samar",
    Guimaras: "Guimaras",
    Ifugao: "Ifugao",
    "Ilocos Norte": "Ilocos Norte",
    "Ilocos Sur": "Ilocos Sur",
    Iloilo: "Iloilo",
    Isabela: "Isabela",
    Kalinga: "Kalinga",
    "La Union": "La Union",
    Laguna: "Laguna",
    "Lanao del Norte": "Lanao del Norte",
    "Lanao del Sur": "Lanao del Sur",
    Leyte: "Leyte",
    Maguindanao: "Maguindanao",
    Marinduque: "Marinduque",
    Masbate: "Masbate",
    "Metro Manila": "Metro Manila",
    "Misamis Occidental": "Misamis Occidental",
    "Misamis Oriental": "Misamis Oriental",
    "Mountain Province": "Mountain Province",
    "Negros Occidental": "Negros Occidental",
    "Negros Oriental": "Negros Oriental",
    "Northern Samar": "Northern Samar",
    "Nueva Ecija": "Nueva Ecija",
    "Nueva Vizcaya": "Nueva Vizcaya",
    "Occidental Mindoro": "Occidental Mindoro",
    "Oriental Mindoro": "Oriental Mindoro",
    Palawan: "Palawan",
    Pampanga: "Pampanga",
    Pangasinan: "Pangasinan",
    Quezon: "Quezon",
    Quirino: "Quirino",
    Rizal: "Rizal",
    Romblon: "Romblon",
    Samar: "Samar",
    Sarangani: "Sarangani",
    Siquijor: "Siquijor",
    Sorsogon: "Sorsogon",
    "South Cotabato": "South Cotabato",
    "Southern Leyte": "Southern Leyte",
    "Sultan Kudarat": "Sultan Kudarat",
    Sulu: "Sulu",
    "Surigao del Norte": "Surigao del Norte",
    "Surigao del Sur": "Surigao del Sur",
    Tarlac: "Tarlac",
    "Tawi-Tawi": "Tawi-Tawi",
    Zambales: "Zambales",
    "Zamboanga del Norte": "Zamboanga del Norte",
    "Zamboanga del Sur": "Zamboanga del Sur",
    "Zamboanga Sibugay": "Zamboanga Sibugay",
  },
  thailand: {
    Bangkok: "Bangkok",
    "Chiang Mai": "Chiang Mai",
    "Chiang Rai": "Chiang Rai",
    Chonburi: "Chonburi",
    Krabi: "Krabi",
    Pattani: "Pattani",
    Phuket: "Phuket",
    "Samut Prakan": "Samut Prakan",
    Songkhla: "Songkhla",
    "Surat Thani": "Surat Thani",
    Trang: "Trang",
    Yala: "Yala",
    Ayutthaya: "Ayutthaya",
    Chachoengsao: "Chachoengsao",
    "Khon Kaen": "Khon Kaen",
    Lampang: "Lampang",
    Loei: "Loei",
    "Nakhon Ratchasima": "Nakhon Ratchasima",
    "Phang Nga": "Phang Nga",
    Phetchaburi: "Phetchaburi",
  },
  malaysia: {
    Johor: "Johor",
    Kedah: "Kedah",
    Kelantan: "Kelantan",
    Malacca: "Malacca",
    "Negeri Sembilan": "Negeri Sembilan",
    Pahang: "Pahang",
    Penang: "Penang",
    Perak: "Perak",
    Perlis: "Perlis",
    Sabah: "Sabah",
    Sarawak: "Sarawak",
    Selangor: "Selangor",
    Terengganu: "Terengganu",
    "Kuala Lumpur": "Kuala Lumpur",
    Labuan: "Labuan",
    Putrajaya: "Putrajaya",
  },
  singapore: {
    "Central Region": "Central Region",
    "East Region": "East Region",
    "North Region": "North Region",
    "Northeast Region": "Northeast Region",
    "West Region": "West Region",
  },
  pakistan: {
    "Azad Jammu and Kashmir": "Azad Jammu and Kashmir",
    Balochistan: "Balochistan",
    "Gilgit-Baltistan": "Gilgit-Baltistan",
    Islamabad: "Islamabad",
    "Khyber Pakhtunkhwa": "Khyber Pakhtunkhwa",
    Punjab: "Punjab",
    Sindh: "Sindh",
  },
  bangladesh: {
    Barisal: "Barisal",
    Chittagong: "Chittagong",
    Dhaka: "Dhaka",
    Khulna: "Khulna",
    Mymensingh: "Mymensingh",
    Rajshahi: "Rajshahi",
    Rangpur: "Rangpur",
    Sylhet: "Sylhet",
  },
  saudi_arabia: {
    Asir: "Asir",
    "Al Bahah": "Al Bahah",
    "Al Jawf": "Al Jawf",
    "Eastern Province": "Eastern Province",
    "Ha'il": "Ha'il",
    Jizan: "Jizan",
    Makkah: "Makkah",
    Medina: "Medina",
    Najran: "Najran",
    "Northern Borders": "Northern Borders",
    Qassim: "Qassim",
    Riyadh: "Riyadh",
    Tabuk: "Tabuk",
  },
  united_arab_emirates: {
    "Abu Dhabi": "Abu Dhabi",
    Ajman: "Ajman",
    Dubai: "Dubai",
    Fujairah: "Fujairah",
    "Ras Al Khaimah": "Ras Al Khaimah",
    Sharjah: "Sharjah",
    "Umm Al Quwain": "Umm Al Quwain",
  },
  israel: {
    "Central District": "Central District",
    "Haifa District": "Haifa District",
    "Jerusalem District": "Jerusalem District",
    "Northern District": "Northern District",
    "Southern District": "Southern District",
    "Tel Aviv District": "Tel Aviv District",
  },
  iran: {
    Alborz: "Alborz",
    Ardabil: "Ardabil",
    Bushehr: "Bushehr",
    "Chaharmahal and Bakhtiari": "Chaharmahal and Bakhtiari",
    "East Azerbaijan": "East Azerbaijan",
    Esfahan: "Esfahan",
    Fars: "Fars",
    Gilan: "Gilan",
    Golestan: "Golestan",
    Hamedan: "Hamedan",
    Hormozgan: "Hormozgan",
    Ilam: "Ilam",
    Kerman: "Kerman",
    Kermanshah: "Kermanshah",
    Khuzestan: "Khuzestan",
    "Kohgiluyeh and Boyer-Ahmad": "Kohgiluyeh and Boyer-Ahmad",
    Kurdistan: "Kurdistan",
    Lorestan: "Lorestan",
    Markazi: "Markazi",
    Mazandaran: "Mazandaran",
    Qazvin: "Qazvin",
    Qom: "Qom",
    Semnan: "Semnan",
    "Sistan and Baluchestan": "Sistan and Baluchestan",
    Tehran: "Tehran",
    Yazd: "Yazd",
  },
  iraq: {
    Baghdad: "Baghdad",
    Basra: "Basra",
    "Dhi Qar": "Dhi Qar",
    Diyala: "Diyala",
    Karbala: "Karbala",
    Kirkuk: "Kirkuk",
    Muthanna: "Muthanna",
    Najaf: "Najaf",
    Nineveh: "Nineveh",
    Qadisiyyah: "Qadisiyyah",
    "Salah ad Din": "Salah ad Din",
    Wasit: "Wasit",
  },
  turkey: {
    Adana: "Adana",
    Adıyaman: "Adıyaman",
    Afyonkarahisar: "Afyonkarahisar",
    Ağrı: "Ağrı",
    Aksaray: "Aksaray",
    Amasya: "Amasya",
    Ankara: "Ankara",
    Antalya: "Antalya",
    Ardahan: "Ardahan",
    Artvin: "Artvin",
    Aydın: "Aydın",
    Balıkesir: "Balıkesir",
    Bartın: "Bartın",
    Batman: "Batman",
    Bayburt: "Bayburt",
    Bilecik: "Bilecik",
    Bingöl: "Bingöl",
    Bitlis: "Bitlis",
    Bolu: "Bolu",
    Burdur: "Burdur",
    Bursa: "Bursa",
    Çanakkale: "Çanakkale",
    Çankırı: "Çankırı",
    Çorum: "Çorum",
    Denizli: "Denizli",
    Diyarbakır: "Diyarbakır",
    Düzce: "Düzce",
    Edirne: "Edirne",
    Elazığ: "Elazığ",
    Erzincan: "Erzincan",
    Erzurum: "Erzurum",
    Eskişehir: "Eskişehir",
    Gaziantep: "Gaziantep",
    Giresun: "Giresun",
    Gümüşhane: "Gümüşhane",
    Hakkari: "Hakkari",
    Hatay: "Hatay",
    Iğdır: "Iğdır",
    Isparta: "Isparta",
    İstanbul: "İstanbul",
    İzmir: "İzmir",
    Kahramanmaraş: "Kahramanmaraş",
    Karabük: "Karabük",
    Karaman: "Karaman",
    Kars: "Kars",
    Kastamonu: "Kastamonu",
    Kayseri: "Kayseri",
    Kırıkkale: "Kırıkkale",
    Kırklareli: "Kırklareli",
    Kırşehir: "Kırşehir",
    Kocaeli: "Kocaeli",
    Konya: "Konya",
    Kütahya: "Kütahya",
    Malatya: "Malatya",
    Manisa: "Manisa",
    Mardin: "Mardin",
    Mersin: "Mersin",
    Muğla: "Muğla",
    Muş: "Muş",
    Nevşehir: "Nevşehir",
    Niğde: "Niğde",
    Ordu: "Ordu",
    Osmaniye: "Osmaniye",
    Rize: "Rize",
    Sakarya: "Sakarya",
    Samsun: "Samsun",
    Siirt: "Siirt",
    Sinop: "Sinop",
    Sivas: "Sivas",
    Şanlıurfa: "Şanlıurfa",
    Şırnak: "Şırnak",
    Tekirdağ: "Tekirdağ",
    Tokat: "Tokat",
    Trabzon: "Trabzon",
    Tunceli: "Tunceli",
    Uşak: "Uşak",
    Van: "Van",
    Yalova: "Yalova",
    Yozgat: "Yozgat",
    Zonguldak: "Zonguldak",
  },
  kazakhstan: {
    Akmola: "Akmola",
    Aktobe: "Aktobe",
    Almaty: "Almaty",
    Atyrau: "Atyrau",
    "East Kazakhstan": "East Kazakhstan",
    Karaganda: "Karaganda",
    Kostanay: "Kostanay",
    Kyzylorda: "Kyzylorda",
    Mangystau: "Mangystau",
    Pavlodar: "Pavlodar",
    "North Kazakhstan": "North Kazakhstan",
    Turkistan: "Turkistan",
    Zhambyl: "Zhambyl",
  },
  nigeria: {
    Abia: "Abia",
    Adamawa: "Adamawa",
    "Akwa Ibom": "Akwa Ibom",
    Anambra: "Anambra",
    Bauchi: "Bauchi",
    Bayelsa: "Bayelsa",
    Benue: "Benue",
    Borno: "Borno",
    "Cross River": "Cross River",
    Delta: "Delta",
    Ebonyi: "Ebonyi",
    Edo: "Edo",
    Ekiti: "Ekiti",
    Enugu: "Enugu",
    Gombe: "Gombe",
    Imo: "Imo",
    Jigawa: "Jigawa",
    Kaduna: "Kaduna",
    Kano: "Kano",
    Kogi: "Kogi",
  },
  south_africa: {
    gauteng: "Gauteng",
    kwazulu_natal: "KwaZulu-Natal",
    western_cape: "Western Cape",
    eastern_cape: "Eastern Cape",
    limpopo: "Limpopo",
    mpumalanga: "Mpumalanga",
    northern_cape: "Northern Cape",
    free_state: "Free State",
  },
  egypt: {
    cairo: "Cairo",
    alexandria: "Alexandria",
    giza: "Giza",
    aswan: "Aswan",
    luxor: "Luxor",
    suez: "Suez",
    port_said: "Port Said",
    dakahlia: "Dakahlia",
    sharqia: "Sharqia",
    kafr_el_sheikh: "Kafr el-Sheikh",
    beni_suef: "Beni Suef",
    minya: "Minya",
    fayoum: "Fayoum",
    qena: "Qena",
    matruh: "Matruh",
    north_sinai: "North Sinai",
    south_sinai: "South Sinai",
  },
  kenya: {
    nairobi: "Nairobi",
    mombasa: "Mombasa",
    kisumu: "Kisumu",
    nakuru: "Nakuru",
    eldoret: "Eldoret",
    kitale: "Kitale",
    meru: "Meru",
    kericho: "Kericho",
    kisii: "Kisii",
    thika: "Thika",
    garissa: "Garissa",
    wajir: "Wajir",
    mandera: "Mandera",
    marsabit: "Marsabit",
    bomet: "Bomet",
    vihiga: "Vihiga",
  },
  ethiopia: {
    addis_ababa: "Addis Ababa",
    oromia: "Oromia",
    amhara: "Amhara",
    tigray: "Tigray",
    somali: "Somali",
    sidama: "Sidama",
    benishangul_gumuz: "Benishangul-Gumuz",
    gambela: "Gambela",
    harari: "Harari",
    afder: "Afder",
    afambo: "Afambo",
    konso: "Konso",
    jems: "Jems",
    gamo: "Gamo",
    arusi: "Arusi",
  },
  ghana: {
    greater_accra: "Greater Accra",
    ashanti: "Ashanti",
    western: "Western",
    eastern: "Eastern",
    central: "Central",
    northern: "Northern",
    upper_west: "Upper West",
    upper_east: "Upper East",
    volta: "Volta",
    western_north: "Western North",
  },
  morocco: {
    casablanca_settat: "Casablanca-Settat",
    rabat_salé_kénitra: "Rabat-Salé-Kénitra",
    marrakech_safi: "Marrakech-Safi",
    tanger_tetouan_alhoceima: "Tanger-Tetouan-Al Hoceima",
    fès_meknès: "Fès-Meknès",
    beni_mellal_khenifra: "Beni Mellal-Khénifra",
    drâa_tafilalet: "Drâa-Tafilalet",
    souss_massa: "Souss-Massa",
    laayoune_sakia_el_hamra: "Laâyoune-Sakia El Hamra",
    dakhla_oued_ed_dahab: "Dakhla-Oued Ed-Dahab",
  },
  tanzania: {
    dar_es_salaam: "Dar es Salaam",
    arusha: "Arusha",
    mwanza: "Mwanza",
    mbeya: "Mbeya",
    kigoma: "Kigoma",
    tanga: "Tanga",
    kilimanjaro: "Kilimanjaro",
    dodoma: "Dodoma",
    shinyanga: "Shinyanga",
    katavi: "Katavi",
    simiyu: "Simiyu",
    mara: "Mara",
    manyara: "Manyara",
    pemba_north: "Pemba North",
    pemba_south: "Pemba South",
    unguja: "Unguja",
  },
  algeria: {
    algiers: "Algiers",
    oran: "Oran",
    constantine: "Constantine",
    annaba: "Annaba",
    blida: "Blida",
    sétif: "Sétif",
    tizi_ouzou: "Tizi Ouzou",
    bejaia: "Bejaia",
    batna: "Batna",
    ouargla: "Ouargla",
    saida: "Saida",
    laghouat: "Laghouat",
    el_oued: "El Oued",
    "m’sila": "M’sila",
    "m’sila": "M’sila",
    bouira: "Bouira",
    khenchela: "Khenchela",
    el_abbas: "El Abbas",
    el_tarf: "El Tarf",
    guelma: "Guelma",
    tlemcen: "Tlemcen",
  },
  uganda: {
    kampala: "Kampala",
    weste: "Weste",
    central: "Central",
    northern: "Northern",
    eastern: "Eastern",
    north_east: "North East",
    west_nile: "West Nile",
    mid_northern: "Mid Northern",
    south_east: "South East",
    south_west: "South West",
    north_west: "North West",
  },
  angola: {
    luanda: "Luanda",
    huila: "Huila",
    namibe: "Namibe",
    cunene: "Cunene",
    benguela: "Benguela",
    malanje: "Malanje",
    cabinda: "Cabinda",
    uíge: "Uíge",
    zaire: "Zaire",
    kuando_kubango: "Kuando Kubango",
    kwanza_norte: "Kwanza Norte",
    kwanza_sul: "Kwanza Sul",
    lunda_norte: "Lunda Norte",
    lunda_sul: "Lunda Sul",
  },
  senegal: {
    dakar: "Dakar",
    thiès: "Thiès",
    saint_louis: "Saint-Louis",
    kaolack: "Kaolack",
    ziguinchor: "Ziguinchor",
    diourbel: "Diourbel",
    fatick: "Fatick",
    kolda: "Kolda",
    tamba_counda: "Tamba Counda",
  },
  cameroon: {
    centre: "Centre",
    littoral: "Littoral",
    north_west: "North West",
    south_west: "South West",
    west: "West",
    adamaoua: "Adamaoua",
    north: "North",
    south: "South",
  },
  zimbabwe: {
    harare: "Harare",
    bulawayo: "Bulawayo",
    manicaland: "Manicaland",
    masvingo: "Masvingo",
    midlands: "Midlands",
    mashonaland_east: "Mashonaland East",
    mashonaland_west: "Mashonaland West",
    mashonaland_central: "Mashonaland Central",
    matabeleland_north: "Matabeleland North",
    matabeleland_south: "Matabeleland South",
  },
  ivory_coast: {
    abidjan: "Abidjan",
    bas_sassandra: "Bas-Sassandra",
    denguelé: "Denguélé",
    vallee_du_bandama: "Vallée du Bandama",
    haut_sassandra: "Haut-Sassandra",
    lacs: "Lacs",
    lagunes: "Lagunes",
    savanes: "Savanes",
    northern: "Northern",
  },
  afghanistan: {
    Kabul: "Kabul",
    Kandahar: "Kandahar",
    Herat: "Herat",
    "Mazar-i-Sharif": "Mazar-i-Sharif",
    Jalalabad: "Jalalabad",
    Ghazni: "Ghazni",
    Khost: "Khost",
    Baghlan: "Baghlan",
    Bamyan: "Bamyan",
    Paktia: "Paktia",
  },
  antigua_and_barbuda: {
    "Saint John's": "Saint John's",
    "Saint George": "Saint George",
    "Saint Paul": "Saint Paul",
    "Saint Peter": "Saint Peter",
    "Saint Philip": "Saint Philip",
  },
  australia: {
    "New South Wales": "New South Wales",
    Victoria: "Victoria",
    Queensland: "Queensland",
    "South Australia": "South Australia",
    "Western Australia": "Western Australia",
    Tasmania: "Tasmania",
    "Australian Capital Territory": "Australian Capital Territory",
    "Northern Territory": "Northern Territory",
  },
  azerbaijan: {
    Baku: "Baku",
    Ganja: "Ganja",
    Lankaran: "Lankaran",
    Mingachevir: "Mingachevir",
    Shaki: "Shaki",
    Sumqayit: "Sumqayit",
    Yevlakh: "Yevlakh",
    Guba: "Guba",
    Zagatala: "Zagatala",
    Gabala: "Gabala",
  },
  bahamas: {
    "New Providence": "New Providence",
    "Grand Bahama": "Grand Bahama",
    Abaco: "Abaco",
    Exuma: "Exuma",
    Andros: "Andros",
    Bimini: "Bimini",
    "Cat Island": "Cat Island",
    Eleuthera: "Eleuthera",
    "Harbour Island": "Harbour Island",
    "Long Island": "Long Island",
  },
  barbados: {
    "Christ Church": "Christ Church",
    "Saint Andrew": "Saint Andrew",
    "Saint George": "Saint George",
    "Saint James": "Saint James",
    "Saint John": "Saint John",
    "Saint Joseph": "Saint Joseph",
    "Saint Lucy": "Saint Lucy",
    "Saint Michael": "Saint Michael",
    "Saint Peter": "Saint Peter",
    "Saint Thomas": "Saint Thomas",
  },
  bahrain: {
    Capital: "Capital",
    Central: "Central",
    Northern: "Northern",
    Southern: "Southern",
  },
  belize: {
    Belize: "Belize",
    Cayo: "Cayo",
    Corozal: "Corozal",
    "Orange Walk": "Orange Walk",
    "Stann Creek": "Stann Creek",
    Toledo: "Toledo",
  },
  benin: {
    Atakora: "Atakora",
    Atlantique: "Atlantique",
    Borgou: "Borgou",
    Collines: "Collines",
    Donga: "Donga",
    Kouffo: "Kouffo",
    Littoral: "Littoral",
    Mono: "Mono",
    Ouémé: "Ouémé",
    Plateau: "Plateau",
    Zou: "Zou",
  },
  burkina_faso: {
    "Boucle du Mouhoun": "Boucle du Mouhoun",
    Cascades: "Cascades",
    Centre: "Centre",
    "Centre-Est": "Centre-Est",
    "Centre-Nord": "Centre-Nord",
    "Centre-Ouest": "Centre-Ouest",
    "Centre-Sud": "Centre-Sud",
    Est: "Est",
    "Hauts-Bassins": "Hauts-Bassins",
    Nord: "Nord",
    Sahel: "Sahel",
    "Sud-Ouest": "Sud-Ouest",
  },
  burundi: {
    Bubanza: "Bubanza",
    Bujumbura: "Bujumbura",
    "Bujumbura Mairie": "Bujumbura Mairie",
    Bururi: "Bururi",
    Cankuzo: "Cankuzo",
    Cibitoke: "Cibitoke",
    Gitega: "Gitega",
    Karuzi: "Karuzi",
    Kayanza: "Kayanza",
    Kirundo: "Kirundo",
    Makamba: "Makamba",
    Muramvya: "Muramvya",
    Muyinga: "Muyinga",
    Ngozi: "Ngozi",
    Rutana: "Rutana",
    Ruyigi: "Ruyigi",
  },
  bhutan: {
    Bumthang: "Bumthang",
    Chhukha: "Chhukha",
    Dagana: "Dagana",
    Gasa: "Gasa",
    Haa: "Haa",
    Lhuentse: "Lhuentse",
    Mongar: "Mongar",
    Paro: "Paro",
    Pemagatshel: "Pemagatshel",
    Punakha: "Punakha",
    "Samdrup Jongkhar": "Samdrup Jongkhar",
    Samtse: "Samtse",
    Sarpang: "Sarpang",
    Thimphu: "Thimphu",
    Trashigang: "Trashigang",
    Trashiyangtse: "Trashiyangtse",
    "Wangdue Phodrang": "Wangdue Phodrang",
    Zhemgang: "Zhemgang",
    Zilung: "Zilung",
  },
  cape_verde: {
    "Boa Vista": "Boa Vista",
    Brava: "Brava",
    Fogo: "Fogo",
    Maio: "Maio",
    Sal: "Sal",
    Santiago: "Santiago",
    "Santo Antão": "Santo Antão",
    "São Nicolau": "São Nicolau",
    "São Vicente": "São Vicente",
  },
  cambodia: {
    "Banteay Meanchey": "Banteay Meanchey",
    Battambang: "Battambang",
    "Kampong Cham": "Kampong Cham",
    "Kampong Chhnang": "Kampong Chhnang",
    "Kampong Speu": "Kampong Speu",
    "Kampong Thom": "Kampong Thom",
    Kandal: "Kandal",
    Kep: "Kep",
    "Koh Kong": "Koh Kong",
    "Krong Kaeb": "Krong Kaeb",
    "Krong Khemara Phoumin": "Krong Khemara Phoumin",
    "Krong Pailin": "Krong Pailin",
    "Krong Preah Sihanouk": "Krong Preah Sihanouk",
    "Krong Siem Reap": "Krong Siem Reap",
    "Krong Tbong Khmum": "Krong Tbong Khmum",
    Mondulkiri: "Mondulkiri",
    Pailin: "Pailin",
    "Preah Vihear": "Preah Vihear",
    "Prey Veng": "Prey Veng",
    Pursat: "Pursat",
    Ratanakiri: "Ratanakiri",
    "Siem Reap": "Siem Reap",
    Sihanoukville: "Sihanoukville",
    "Svay Rieng": "Svay Rieng",
    Takeo: "Takeo",
    "Tboung Khmum": "Tboung Khmum",
  },
  qatar: {
    "Ad Dawhah": "Ad Dawhah",
    "Al Daayen": "Al Daayen",
    "Al Khor": "Al Khor",
    "Al Rayyan": "Al Rayyan",
    "Al Shamal": "Al Shamal",
    "Al Wakrah": "Al Wakrah",
    "Umm Salal": "Umm Salal",
    "Al Shahaniya": "Al Shahaniya",
  },
  chad: {
    Batha: "Batha",
    Borkou: "Borkou",
    "Chari-Baguirmi": "Chari-Baguirmi",
    "Ennedi-Est": "Ennedi-Est",
    "Ennedi-Ouest": "Ennedi-Ouest",
    Guéra: "Guéra",
    "Hadjer-Lamis": "Hadjer-Lamis",
    Kanem: "Kanem",
    Lac: "Lac",
    "Logone Occidental": "Logone Occidental",
    "Logone Oriental": "Logone Oriental",
    Mandoul: "Mandoul",
    "Mayo-Kebbi Est": "Mayo-Kebbi Est",
    "Mayo-Kebbi Ouest": "Mayo-Kebbi Ouest",
    "Moyen-Chari": "Moyen-Chari",
    Ouaddaï: "Ouaddaï",
    Salamat: "Salamat",
    Sila: "Sila",
    Tandjilé: "Tandjilé",
    Tibesti: "Tibesti",
    "Wadi Fira": "Wadi Fira",
  },
  vatican_city: { "Vatican City": "Vatican City" },
  comoros: {
    "Grande Comore": "Grande Comore",
    Anjouan: "Anjouan",
    Moheli: "Moheli",
  },
  north_korea: {
    Chagang: "Chagang",
    "North Hamgyong": "North Hamgyong",
    "South Hamgyong": "South Hamgyong",
    "North Hwanghae": "North Hwanghae",
    "South Hwanghae": "South Hwanghae",
    Kangwon: "Kangwon",
    "North Pyongan": "North Pyongan",
    "South Pyongan": "South Pyongan",
    Ryanggang: "Ryanggang",
    Pyongyang: "Pyongyang",
    Rason: "Rason",
  },
  cote_d_ivoire: {
    Abidjan: "Abidjan",
    "Bas-Sassandra": "Bas-Sassandra",
    Comoé: "Comoé",
    Denguélé: "Denguélé",
    "Gôh-Djiboua": "Gôh-Djiboua",
    Lacs: "Lacs",
    Lagunes: "Lagunes",
    Montagnes: "Montagnes",
    "Sassandra-Marahoué": "Sassandra-Marahoué",
    Savanes: "Savanes",
    "Vallée du Bandama": "Vallée du Bandama",
    Woroba: "Woroba",
    Yamoussoukro: "Yamoussoukro",
    Zanzan: "Zanzan",
  },
  dominica: {
    "Saint Andrew": "Saint Andrew",
    "Saint David": "Saint David",
    "Saint George": "Saint George",
    "Saint John": "Saint John",
    "Saint Joseph": "Saint Joseph",
    "Saint Luke": "Saint Luke",
    "Saint Mark": "Saint Mark",
    "Saint Patrick": "Saint Patrick",
    "Saint Paul": "Saint Paul",
    "Saint Peter": "Saint Peter",
  },
  eritrea: {
    Anseba: "Anseba",
    Debub: "Debub",
    "Debubawi Keyih Bahri": "Debubawi Keyih Bahri",
    "Gash-Barka": "Gash-Barka",
    Maekel: "Maekel",
    "Semenawi Keyih Bahri": "Semenawi Keyih Bahri",
  },
  fiji: {
    Ba: "Ba",
    Bua: "Bua",
    Cakaudrove: "Cakaudrove",
    Kadavu: "Kadavu",
    Lau: "Lau",
    Lomaiviti: "Lomaiviti",
    Macuata: "Macuata",
    "Nadroga-Navosa": "Nadroga-Navosa",
    Naitasiri: "Naitasiri",
    Namosi: "Namosi",
    Ra: "Ra",
    Rewa: "Rewa",
    Rotuma: "Rotuma",
    Serua: "Serua",
    Tailevu: "Tailevu",
  },
  gabon: {
    Estuaire: "Estuaire",
    "Haut-Ogooué": "Haut-Ogooué",
    "Moyen-Ogooué": "Moyen-Ogooué",
    Ngounié: "Ngounié",
    Nyanga: "Nyanga",
    "Ogooué-Ivindo": "Ogooué-Ivindo",
    "Ogooué-Lolo": "Ogooué-Lolo",
    "Ogooué-Maritime": "Ogooué-Maritime",
    "Woleu-Ntem": "Woleu-Ntem",
  },
  gambia: {
    Banjul: "Banjul",
    "Central River": "Central River",
    "Lower River": "Lower River",
    "North Bank": "North Bank",
    "Upper River": "Upper River",
    Western: "Western",
  },
  grenada: {
    "Saint Andrew": "Saint Andrew",
    "Saint David": "Saint David",
    "Saint George": "Saint George",
    "Saint John": "Saint John",
    "Saint Mark": "Saint Mark",
    "Saint Patrick": "Saint Patrick",
    "Carriacou and Petite Martinique": "Carriacou and Petite Martinique",
  },
  guyana: {
    "Barima-Waini": "Barima-Waini",
    "Cuyuni-Mazaruni": "Cuyuni-Mazaruni",
    "Demerara-Mahaica": "Demerara-Mahaica",
    "East Berbice-Corentyne": "East Berbice-Corentyne",
    "Essequibo Islands-West Demerara": "Essequibo Islands-West Demerara",
    "Mahaica-Berbice": "Mahaica-Berbice",
    "Pomeroon-Supenaam": "Pomeroon-Supenaam",
    "Potaro-Siparuni": "Potaro-Siparuni",
    "Upper Demerara-Berbice": "Upper Demerara-Berbice",
    "Upper Takutu-Upper Essequibo": "Upper Takutu-Upper Essequibo",
  },
  guinea: {
    Beyla: "Beyla",
    Boffa: "Boffa",
    Boké: "Boké",
    Conakry: "Conakry",
    Coyah: "Coyah",
    Dabola: "Dabola",
    Dalaba: "Dalaba",
    Dinguiraye: "Dinguiraye",
    Dubréka: "Dubréka",
    Faranah: "Faranah",
    Forécariah: "Forécariah",
    Fria: "Fria",
    Gaoual: "Gaoual",
    Guékédou: "Guékédou",
    Kankan: "Kankan",
    Kérouané: "Kérouané",
    Kindia: "Kindia",
    Kissidougou: "Kissidougou",
    Koubia: "Koubia",
    Koundara: "Koundara",
    Kouroussa: "Kouroussa",
    Labé: "Labé",
    Lélouma: "Lélouma",
    Lola: "Lola",
    Macenta: "Macenta",
    Mali: "Mali",
    Mamou: "Mamou",
    Nzérékoré: "Nzérékoré",
    Pita: "Pita",
    Siguiri: "Siguiri",
    Télimélé: "Télimélé",
    Tougué: "Tougué",
    Yomou: "Yomou",
  },
  equatorial_guinea: {
    Annobón: "Annobón",
    "Bioko Norte": "Bioko Norte",
    "Bioko Sur": "Bioko Sur",
    "Centro Sur": "Centro Sur",
    "Kie-Ntem": "Kie-Ntem",
    Litoral: "Litoral",
    "Wele-Nzas": "Wele-Nzas",
  },
  guinea_bissau: {
    Bafatá: "Bafatá",
    Biombo: "Biombo",
    Bissau: "Bissau",
    Bolama: "Bolama",
    Cacheu: "Cacheu",
    Gabú: "Gabú",
    Oio: "Oio",
    Quinara: "Quinara",
    Tombali: "Tombali",
  },
  haiti: {
    Artibonite: "Artibonite",
    Centre: "Centre",
    "Grand'Anse": "Grand'Anse",
    Nippes: "Nippes",
    Nord: "Nord",
    "Nord-Est": "Nord-Est",
    "Nord-Ouest": "Nord-Ouest",
    Ouest: "Ouest",
    Sud: "Sud",
    "Sud-Est": "Sud-Est",
  },
  kyrgyzstan: {
    Batken: "Batken",
    Chuy: "Chuy",
    "Jalal-Abad": "Jalal-Abad",
    Naryn: "Naryn",
    Osh: "Osh",
    Talas: "Talas",
    "Ysyk-Köl": "Ysyk-Köl",
    Bishkek: "Bishkek",
  },
  kiribati: {
    "Gilbert Islands": "Gilbert Islands",
    "Line Islands": "Line Islands",
    "Phoenix Islands": "Phoenix Islands",
  },
  kuwait: {
    "Al Ahmadi": "Al Ahmadi",
    "Al Farwaniyah": "Al Farwaniyah",
    "Al Jahra": "Al Jahra",
    Capital: "Capital",
    Hawalli: "Hawalli",
    "Mubarak Al-Kabeer": "Mubarak Al-Kabeer",
  },
  laos: {
    Attapeu: "Attapeu",
    Bokeo: "Bokeo",
    Bolikhamsai: "Bolikhamsai",
    Champasak: "Champasak",
    Houaphanh: "Houaphanh",
    Khammouane: "Khammouane",
    "Luang Namtha": "Luang Namtha",
    "Luang Prabang": "Luang Prabang",
    Oudomxay: "Oudomxay",
    Phongsaly: "Phongsaly",
    Salavan: "Salavan",
    Savannakhet: "Savannakhet",
    Vientiane: "Vientiane",
    Xaignabouli: "Xaignabouli",
    Xaisomboun: "Xaisomboun",
    Sekong: "Sekong",
    Xiangkhouang: "Xiangkhouang",
  },
  lesotho: {
    Berea: "Berea",
    "Butha-Buthe": "Butha-Buthe",
    Leribe: "Leribe",
    Mafeteng: "Mafeteng",
    Maseru: "Maseru",
    "Mohale's Hoek": "Mohale's Hoek",
    Mokhotlong: "Mokhotlong",
    "Qacha's Nek": "Qacha's Nek",
    Quthing: "Quthing",
    "Thaba-Tseka": "Thaba-Tseka",
  },
  lebanon: {
    Akkar: "Akkar",
    "Baalbek-Hermel": "Baalbek-Hermel",
    Beirut: "Beirut",
    Bekaa: "Bekaa",
    "Mount Lebanon": "Mount Lebanon",
    Nabatieh: "Nabatieh",
    "North Lebanon": "North Lebanon",
    "South Lebanon": "South Lebanon",
  },
  liberia: {
    Bomi: "Bomi",
    Bong: "Bong",
    Gbarpolu: "Gbarpolu",
    "Grand Bassa": "Grand Bassa",
    "Grand Cape Mount": "Grand Cape Mount",
    "Grand Gedeh": "Grand Gedeh",
    "Grand Kru": "Grand Kru",
    Lofa: "Lofa",
    Margibi: "Margibi",
    Maryland: "Maryland",
    Montserrado: "Montserrado",
    Nimba: "Nimba",
    "River Cess": "River Cess",
    "River Gee": "River Gee",
    Sinoe: "Sinoe",
  },
  libya: {
    "Al Butnan": "Al Butnan",
    "Al Jabal al Akhdar": "Al Jabal al Akhdar",
    "Al Jabal al Gharbi": "Al Jabal al Gharbi",
    "Al Jafara": "Al Jafara",
    "Al Kufra": "Al Kufra",
    "Al Marj": "Al Marj",
    "Al Marqab": "Al Marqab",
    "Al Wahat": "Al Wahat",
    "An Nuqat al Khams": "An Nuqat al Khams",
    "Az Zawiyah": "Az Zawiyah",
    Benghazi: "Benghazi",
    Darnah: "Darnah",
    Ghat: "Ghat",
    Murzuq: "Murzuq",
    Nalut: "Nalut",
    Sabha: "Sabha",
    Surt: "Surt",
    Tripoli: "Tripoli",
    "Wadi al Hayaa": "Wadi al Hayaa",
    "Wadi al Shatii": "Wadi al Shatii",
  },
  madagascar: {
    Antananarivo: "Antananarivo",
    Antsiranana: "Antsiranana",
    Fianarantsoa: "Fianarantsoa",
    Mahajanga: "Mahajanga",
    Toamasina: "Toamasina",
    Toliara: "Toliara",
  },
  malawi: {
    Balaka: "Balaka",
    Blantyre: "Blantyre",
    Chikwawa: "Chikwawa",
    Chiradzulu: "Chiradzulu",
    Chitipa: "Chitipa",
    Dedza: "Dedza",
    Dowa: "Dowa",
    Karonga: "Karonga",
    Kasungu: "Kasungu",
    Lilongwe: "Lilongwe",
    Machinga: "Machinga",
    Mangochi: "Mangochi",
    Mchinji: "Mchinji",
    Mulanje: "Mulanje",
    Mwanza: "Mwanza",
    Mzimba: "Mzimba",
    Neno: "Neno",
    "Nkhata Bay": "Nkhata Bay",
    Nkhotakota: "Nkhotakota",
    Nsanje: "Nsanje",
    Ntcheu: "Ntcheu",
    Ntchisi: "Ntchisi",
    Phalombe: "Phalombe",
    Rumphi: "Rumphi",
    Salima: "Salima",
    Thyolo: "Thyolo",
    Zomba: "Zomba",
  },
  maldives: {
    "Alif Alif": "Alif Alif",
    "Alif Dhaal": "Alif Dhaal",
    Baa: "Baa",
    Dhaalu: "Dhaalu",
    Faafu: "Faafu",
    "Gaafu Alif": "Gaafu Alif",
    "Gaafu Dhaalu": "Gaafu Dhaalu",
    Gnaviyani: "Gnaviyani",
    "Haa Alif": "Haa Alif",
    "Haa Dhaalu": "Haa Dhaalu",
    Kaafu: "Kaafu",
    Laamu: "Laamu",
    Lhaviyani: "Lhaviyani",
    Malé: "Malé",
    Meemu: "Meemu",
    Noonu: "Noonu",
    Raa: "Raa",
    Seenu: "Seenu",
    Shaviyani: "Shaviyani",
    Thaa: "Thaa",
    Vaavu: "Vaavu",
  },
  mali: {
    Bamako: "Bamako",
    Gao: "Gao",
    Kayes: "Kayes",
    Kidal: "Kidal",
    Koulikoro: "Koulikoro",
    Mopti: "Mopti",
    Ségou: "Ségou",
    Sikasso: "Sikasso",
    Timbuktu: "Timbuktu",
  },
  mauritius: {
    "Black River": "Black River",
    Flacq: "Flacq",
    "Grand Port": "Grand Port",
    Moka: "Moka",
    Pamplemousses: "Pamplemousses",
    "Plaines Wilhems": "Plaines Wilhems",
    "Port Louis": "Port Louis", // Capital
    "Rivière du Rempart": "Rivière du Rempart",
    Savanne: "Savanne",
    Rodrigues: "Rodrigues", // Isla
    Agalega: "Agalega", // Isla
    "Cargados Carajos": "Cargados Carajos", // Isla
  },
  mauritania: {
    Adrar: "Adrar",
    Assaba: "Assaba",
    Brakna: "Brakna",
    "Dakhlet Nouadhibou": "Dakhlet Nouadhibou",
    Gorgol: "Gorgol",
    Guidimaka: "Guidimaka",
    "Hodh Ech Chargui": "Hodh Ech Chargui",
    "Hodh El Gharbi": "Hodh El Gharbi",
    Inchiri: "Inchiri",
    "Nouakchott Nord": "Nouakchott Nord",
    "Nouakchott Ouest": "Nouakchott Ouest",
    "Nouakchott Sud": "Nouakchott Sud",
    Tagant: "Tagant",
    "Tiris Zemmour": "Tiris Zemmour",
    Trarza: "Trarza",
  },
  micronesia: {
    Chuuk: "Chuuk",
    Kosrae: "Kosrae",
    Pohnpei: "Pohnpei",
    Yap: "Yap",
  },
  mongolia: {
    Arkhangai: "Arkhangai",
    "Bayan-Ölgii": "Bayan-Ölgii",
    Bayankhongor: "Bayankhongor",
    Bulgan: "Bulgan",
    "Darkhan-Uul": "Darkhan-Uul",
    Dornod: "Dornod",
    Dornogovi: "Dornogovi",
    Dundgovi: "Dundgovi",
    "Govi-Altai": "Govi-Altai",
    Govisümber: "Govisümber",
    Khentii: "Khentii",
    Khovd: "Khovd",
    Khuvsgul: "Khuvsgul",
    Orkhon: "Orkhon",
    Ömnögovi: "Ömnögovi",
    Selenge: "Selenge",
    Sukhbaatar: "Sukhbaatar",
    Töv: "Töv",
    Uvs: "Uvs",
    Zavkhan: "Zavkhan",
    Ulaanbaatar: "Ulaanbaatar", // Capital
  },
  mozambique: {
    "Cabo Delgado": "Cabo Delgado",
    Gaza: "Gaza",
    Inhambane: "Inhambane",
    Manica: "Manica",
    Maputo: "Maputo", // Capital
    "Maputo Province": "Maputo Province",
    Nampula: "Nampula",
    Niassa: "Niassa",
    Sofala: "Sofala",
    Tete: "Tete",
    Zambezia: "Zambezia",
  },
  namibia: {
    Erongo: "Erongo",
    Hardap: "Hardap",
    Karas: "Karas",
    "Kavango East": "Kavango East",
    "Kavango West": "Kavango West",
    Khomas: "Khomas", // Contiene la capital Windhoek
    Kunene: "Kunene",
    Ohangwena: "Ohangwena",
    Omaheke: "Omaheke",
    Omusati: "Omusati",
    Oshana: "Oshana",
    Oshikoto: "Oshikoto",
    Otjozondjupa: "Otjozondjupa",
    Zambezi: "Zambezi",
  },
  nauru: {
    Aiwo: "Aiwo",
    Anabar: "Anabar",
    Anetan: "Anetan",
    Anibare: "Anibare",
    Baiti: "Baiti",
    Boe: "Boe",
    Buada: "Buada",
    Denigomodu: "Denigomodu",
    Ewa: "Ewa",
    Ijuw: "Ijuw",
    Meneng: "Meneng",
    Nibok: "Nibok",
    Uaboe: "Uaboe",
    Yaren: "Yaren", // Capital de facto
  },
  nepal: {
    Bagmati: "Bagmati",
    Gandaki: "Gandaki",
    Karnali: "Karnali",
    Lumbini: "Lumbini",
    Madhesh: "Madhesh",
    "Province 1": "Province 1",
    Sudurpashchim: "Sudurpashchim",
    Kathmandu: "Kathmandu", // Capital
  },
  niger: {
    Agadez: "Agadez",
    Diffa: "Diffa",
    Dosso: "Dosso",
    Maradi: "Maradi",
    Tahoua: "Tahoua",
    Tillabéri: "Tillabéri",
    Zinder: "Zinder",
    Niamey: "Niamey", // Capital
  },
  new_zealand: {
    Auckland: "Auckland",
    "Bay of Plenty": "Bay of Plenty",
    Canterbury: "Canterbury",
    Gisborne: "Gisborne",
    "Hawke's Bay": "Hawke's Bay",
    "Manawatu-Wanganui": "Manawatu-Wanganui",
    Marlborough: "Marlborough",
    Nelson: "Nelson",
    Northland: "Northland",
    Otago: "Otago",
    Southland: "Southland",
    Taranaki: "Taranaki",
    Tasman: "Tasman",
    Waikato: "Waikato",
    Wellington: "Wellington",
    "West Coast": "West Coast",
  },
  palau: {
    Aimeliik: "Aimeliik",
    Airai: "Airai",
    Angaur: "Angaur",
    Hatohobei: "Hatohobei",
    Kayangel: "Kayangel",
    Koror: "Koror", // Capital
    Melekeok: "Melekeok",
    Ngaraard: "Ngaraard",
    Ngarchelong: "Ngarchelong",
    Ngardmau: "Ngardmau",
    Ngatpang: "Ngatpang",
    Ngchesar: "Ngchesar",
    Ngeremlengui: "Ngeremlengui",
    Ngiwal: "Ngiwal",
    Peleliu: "Peleliu",
    Sonsorol: "Sonsorol",
  },
  papua_new_guinea: {
    Bougainville: "Bougainville",
    Central: "Central",
    Chimbu: "Chimbu",
    "Eastern Highlands": "Eastern Highlands",
    "East New Britain": "East New Britain",
    "East Sepik": "East Sepik",
    Enga: "Enga",
    Gulf: "Gulf",
    Hela: "Hela",
    Jiwaka: "Jiwaka",
    Madang: "Madang",
    Manus: "Manus",
    "Milne Bay": "Milne Bay",
    Morobe: "Morobe",
    "New Ireland": "New Ireland",
    Northern: "Northern",
    "Southern Highlands": "Southern Highlands",
    Western: "Western",
    "Western Highlands": "Western Highlands",
    "West New Britain": "West New Britain",
    "West Sepik": "West Sepik",
    "National Capital District": "National Capital District", // Capital (Port Moresby)
  },
  rwanda: {
    "Eastern Province": "Eastern Province",
    Kigali: "Kigali", // Capital
    "Northern Province": "Northern Province",
    "Southern Province": "Southern Province",
    "Western Province": "Western Province",
  },
  saint_kitts_and_nevis: {
    "Christ Church Nichola Town": "Christ Church Nichola Town",
    "Saint Anne Sandy Point": "Saint Anne Sandy Point",
    "Saint George Basseterre": "Saint George Basseterre",
    "Saint George Gingerland": "Saint George Gingerland",
    "Saint James Windward": "Saint James Windward",
    "Saint John Capisterre": "Saint John Capisterre",
    "Saint John Figtree": "Saint John Figtree",
    "Saint Mary Cayon": "Saint Mary Cayon",
    "Saint Paul Capisterre": "Saint Paul Capisterre",
    "Saint Paul Charlestown": "Saint Paul Charlestown",
    "Saint Peter Basseterre": "Saint Peter Basseterre",
    "Saint Thomas Lowland": "Saint Thomas Lowland",
    "Saint Thomas Middle Island": "Saint Thomas Middle Island",
    "Trinity Palmetto Point": "Trinity Palmetto Point",
  },
  san_marino: {
    Acquaviva: "Acquaviva",
    "Borgo Maggiore": "Borgo Maggiore",
    Chiesanuova: "Chiesanuova",
    Domagnano: "Domagnano",
    Faetano: "Faetano",
    Fiorentino: "Fiorentino",
    Montegiardino: "Montegiardino",
    "San Marino": "San Marino", // Capital
    Serravalle: "Serravalle",
  },
  saint_vincent_and_the_grenadines: {
    Charlotte: "Charlotte",
    Grenadines: "Grenadines",
    "Saint Andrew": "Saint Andrew",
    "Saint David": "Saint David",
    "Saint George": "Saint George",
    "Saint Patrick": "Saint Patrick",
  },
  saint_lucia: {
    "Anse la Raye": "Anse la Raye",
    Canaries: "Canaries",
    Castries: "Castries", // Capital
    Choiseul: "Choiseul",
    Dennery: "Dennery",
    "Gros Islet": "Gros Islet",
    Laborie: "Laborie",
    Micoud: "Micoud",
    Soufriere: "Soufriere",
    "Vieux Fort": "Vieux Fort",
  },
  sao_tome_and_principe: {
    "Água Grande": "Água Grande", // Capital (Sao Tomé)
    Cantagalo: "Cantagalo",
    Caué: "Caué",
    Lembá: "Lembá",
    Lobata: "Lobata",
    "Mé-Zóchi": "Mé-Zóchi",
    Pagué: "Pagué", // Isla de Príncipe
  },
  seychelles: {
    "Anse aux Pins": "Anse aux Pins",
    "Anse Boileau": "Anse Boileau",
    "Anse Etoile": "Anse Etoile",
    "Anse Royale": "Anse Royale",
    "Baie Lazare": "Baie Lazare",
    "Baie Sainte Anne": "Baie Sainte Anne",
    "Beau Vallon": "Beau Vallon",
    "Bel Air": "Bel Air",
    "Bel Ombre": "Bel Ombre",
    Cascade: "Cascade",
    "English River": "English River",
    Glacis: "Glacis",
    "Grand Anse Mahe": "Grand Anse Mahe",
    "Grand Anse Praslin": "Grand Anse Praslin",
    "La Digue": "La Digue",
    "Mont Buxton": "Mont Buxton",
    "Mont Fleuri": "Mont Fleuri",
    Plaisance: "Plaisance",
    "Pointe La Rue": "Pointe La Rue",
    "Port Glaud": "Port Glaud",
    "Roche Caiman": "Roche Caiman",
    "Saint Louis": "Saint Louis",
    Takamaka: "Takamaka",
  },
  sierra_leone: {
    Kailahun: "Kailahun",
    Kenema: "Kenema",
    Kono: "Kono",
    Bombali: "Bombali",
    Kambia: "Kambia",
    Koinadugu: "Koinadugu",
    "Port Loko": "Port Loko",
    Tonkolili: "Tonkolili",
    Bo: "Bo",
    Bonthe: "Bonthe",
    Moyamba: "Moyamba",
    Pujehun: "Pujehun",
    "Western Area Rural": "Western Area Rural",
    "Western Area Urban": "Western Area Urban", // Capital (Freetown)
  },
  syria: {
    "Al-Hasakah": "Al-Hasakah",
    Aleppo: "Aleppo",
    Damascus: "Damascus", // Capital
    Daraa: "Daraa",
    "Deir ez-Zor": "Deir ez-Zor",
    Hama: "Hama",
    Homs: "Homs",
    Idlib: "Idlib",
    Latakia: "Latakia",
    Quneitra: "Quneitra",
    Raqqa: "Raqqa",
    "Rif Dimashq": "Rif Dimashq",
    "As-Suwayda": "As-Suwayda",
    Tartus: "Tartus",
  },
  somalia: {
    Awdal: "Awdal",
    Bakool: "Bakool",
    Banaadir: "Banaadir", // Capital (Mogadishu)
    Bari: "Bari",
    Bay: "Bay",
    Galguduud: "Galguduud",
    Gedo: "Gedo",
    Hiiraan: "Hiiraan",
    "Lower Juba": "Lower Juba",
    "Lower Shabelle": "Lower Shabelle",
    "Middle Juba": "Middle Juba",
    "Middle Shabelle": "Middle Shabelle",
    Mudug: "Mudug",
    Nugal: "Nugal",
    Sanaag: "Sanaag",
    Sool: "Sool",
    Togdheer: "Togdheer",
    "Woqooyi Galbeed": "Woqooyi Galbeed",
  },
  sri_lanka: {
    "Central Province": "Central Province",
    "Eastern Province": "Eastern Province",
    "North Central Province": "North Central Province",
    "Northern Province": "Northern Province",
    "North Western Province": "North Western Province",
    "Sabaragamuwa Province": "Sabaragamuwa Province",
    "Southern Province": "Southern Province",
    "Uva Province": "Uva Province",
    "Western Province": "Western Province", // Capital (Colombo)
  },
  swaziland: {
    Hhohho: "Hhohho",
    Lubombo: "Lubombo",
    Manzini: "Manzini",
    Shiselweni: "Shiselweni",
    Mbabane: "Mbabane", // Capital
  },
  sudan: {
    "Al Jazirah": "Al Jazirah",
    "Al Qadarif": "Al Qadarif",
    "Blue Nile": "Blue Nile",
    "Central Darfur": "Central Darfur",
    "East Darfur": "East Darfur",
    Kassala: "Kassala",
    Khartoum: "Khartoum", // Capital
    "North Darfur": "North Darfur",
    "North Kordofan": "North Kordofan",
    Northern: "Northern",
    "Red Sea": "Red Sea",
    "River Nile": "River Nile",
    Sennar: "Sennar",
    "South Darfur": "South Darfur",
    "South Kordofan": "South Kordofan",
    "West Darfur": "West Darfur",
    "West Kordofan": "West Kordofan",
    "White Nile": "White Nile",
  },
  south_sudan: {
    "Central Equatoria": "Central Equatoria",
    "Eastern Equatoria": "Eastern Equatoria",
    Jonglei: "Jonglei",
    Lakes: "Lakes",
    "Northern Bahr el Ghazal": "Northern Bahr el Ghazal",
    Unity: "Unity",
    "Upper Nile": "Upper Nile",
    Warrap: "Warrap",
    "Western Bahr el Ghazal": "Western Bahr el Ghazal",
    "Western Equatoria": "Western Equatoria",
  },
  suriname: {
    Brokopondo: "Brokopondo",
    Commewijne: "Commewijne",
    Coronie: "Coronie",
    Marowijne: "Marowijne",
    Nickerie: "Nickerie",
    Para: "Para",
    Paramaribo: "Paramaribo", // Capital
    Saramacca: "Saramacca",
    Sipaliwini: "Sipaliwini",
    Wanica: "Wanica",
  },
  tajikistan: {
    Dushanbe: "Dushanbe", // Capital
    "Gorno-Badakhshan": "Gorno-Badakhshan",
    Khatlon: "Khatlon",
    Sughd: "Sughd",
    "Districts of Republican Subordination":
      "Districts of Republican Subordination",
  },
  timor_leste: {
    Aileu: "Aileu",
    Ainaro: "Ainaro",
    Baucau: "Baucau",
    Bobonaro: "Bobonaro",
    "Cova Lima": "Cova Lima",
    Dili: "Dili", // Capital
    Ermera: "Ermera",
    Lautém: "Lautém",
    Liquiçá: "Liquiçá",
    Manatuto: "Manatuto",
    Manufahi: "Manufahi",
    Viqueque: "Viqueque",
  },
  togo: {
    Centrale: "Centrale",
    Kara: "Kara",
    Maritime: "Maritime",
    Plateaux: "Plateaux",
    Savanes: "Savanes",
  },
  tonga: {
    Eua: "'Eua",
    Haapai: "Ha'apai",
    Niuas: "Niuas",
    Tongatapu: "Tongatapu", // Capital (Nuku'alofa)
    Vavaʻu: "Vava'u",
  },
  trinidad_and_tobago: {
    Arima: "Arima",
    Chaguanas: "Chaguanas",
    "Couva-Tabaquite-Talparo": "Couva-Tabaquite-Talparo",
    "Diego Martin": "Diego Martin",
    "Mayaro-Rio Claro": "Mayaro-Rio Claro",
    "Penal-Debe": "Penal-Debe",
    "Point Fortin": "Point Fortin",
    "Port of Spain": "Port of Spain", // Capital
    "Princes Town": "Princes Town",
    "San Fernando": "San Fernando",
    "San Juan-Laventille": "San Juan-Laventille",
    "Sangre Grande": "Sangre Grande",
    Siparia: "Siparia",
    Tobago: "Tobago",
    "Tunapuna-Piarco": "Tunapuna-Piarco",
  },
  tunisia: {
    Ariana: "Ariana",
    Beja: "Beja",
    "Ben Arous": "Ben Arous",
    Bizerte: "Bizerte",
    Gabes: "Gabes",
    Gafsa: "Gafsa",
    Jendouba: "Jendouba",
    Kairouan: "Kairouan",
    Kasserine: "Kasserine",
    Kebili: "Kebili",
    Kef: "Kef",
    Mahdia: "Mahdia",
    Manouba: "Manouba",
    Medenine: "Medenine",
    Monastir: "Monastir",
    Nabeul: "Nabeul",
    Sfax: "Sfax",
    "Sidi Bouzid": "Sidi Bouzid",
    Siliana: "Siliana",
    Sousse: "Sousse",
    Tataouine: "Tataouine",
    Tozeur: "Tozeur",
    Tunis: "Tunis", // Capital
    Zaghouan: "Zaghouan",
  },
  turkmenistan: {
    Ahal: "Ahal",
    Balkan: "Balkan",
    Dashoguz: "Dashoguz",
    Lebap: "Lebap",
    Mary: "Mary",
    Ashgabat: "Ashgabat", // Capital
  },
  tuvalu: {
    Funafuti: "Funafuti", // Capital
    Nanumanga: "Nanumanga",
    Nanumea: "Nanumea",
    Niutao: "Niutao",
    Nui: "Nui",
    Nukufetau: "Nukufetau",
    Nukulaelae: "Nukulaelae",
    Vaitupu: "Vaitupu",
  },
  uzbekistan: {
    Andijan: "Andijan",
    Bukhara: "Bukhara",
    Fergana: "Fergana",
    Jizzakh: "Jizzakh",
    Karakalpakstan: "Karakalpakstan",
    Khorezm: "Khorezm",
    Namangan: "Namangan",
    Navoiy: "Navoiy",
    Samarkand: "Samarkand",
    Sirdaryo: "Sirdaryo",
    Surxondaryo: "Surxondaryo",
    Tashkent: "Tashkent", // Capital
    Qashqadaryo: "Qashqadaryo",
  },
  vanuatu: {
    Malampa: "Malampa",
    Penama: "Penama",
    Sanma: "Sanma",
    Shefa: "Shefa", // Capital (Port Vila)
    Tafea: "Tafea",
    Torba: "Torba",
  },
  yemen: {
    Abyan: "Abyan",
    Aden: "Aden", // Capital alterna
    "Al Bayda": "Al Bayda",
    "Al Hudaydah": "Al Hudaydah",
    "Al Jawf": "Al Jawf",
    "Al Mahrah": "Al Mahrah",
    "Al Mahwit": "Al Mahwit",
    "Amanat Al Asimah": "Amanat Al Asimah", // Capital (Sana'a)
    Dhamar: "Dhamar",
    Hadhramaut: "Hadhramaut",
    Hajjah: "Hajjah",
    Ibb: "Ibb",
    Lahij: "Lahij",
    Marib: "Marib",
    Raymah: "Raymah",
    Saada: "Saada",
    Shabwah: "Shabwah",
    Socotra: "Socotra",
    Taiz: "Taiz",
  },
  djibouti: {
    "Ali Sabieh": "Ali Sabieh",
    Arta: "Arta",
    Dikhil: "Dikhil",
    Djibouti: "Djibouti", // Capital
    Obock: "Obock",
    Tadjourah: "Tadjourah",
  },
  zambia: {
    Central: "Central",
    Copperbelt: "Copperbelt",
    Eastern: "Eastern",
    Luapula: "Luapula",
    Lusaka: "Lusaka", // Capital
    Muchinga: "Muchinga",
    Northern: "Northern",
    "North-Western": "North-Western",
    Southern: "Southern",
    Western: "Western",
  },
};
export const categorias = {
  prebenjamin: "PreBenjamín",
  benjamin: "Benjamín",
  infantil: "Infantil",
  cadete: "Cadete",
  juvenil: "Juvenil",
  sub23: "Sub-23",
  senior: "Senior",
};

export const modulos = [
  "Panel de control",
  "Jugadores",
  "Agentes",
  "Pedidos",
  "Videos",
  "Verificaciones",
  "Verificaciones Afiliados",
  "Afiliados",
  "Consultas",
  "Agentes Nuevos",
  "Cupones",
  "Retiros",
  "Correo",
  "Usuarios panel",
];
export const posiciones = {
  goalKeeper: "Portero",
  LD: "Lateral Derecho",
  LI: "Lateral Izquierdo",
  DFD: "Defensa Central Derecho",
  DFI: "Defensa Central Izquierdo",
  MCD: "Mediocampista Defensivo",
  MDD: "Mediocampista Derecho",
  MDI: "Mediocampista Central",
  DC: "Delantero Centro",
  MCO: "Mediocampista Ofensivo",
  EXT: "Extremo Izquierdo",
};

export const selecciones = {
  male: "Masculina",
  female: "Femenina",
  none: "Ninguna",
};
export const piesDominantes = {
  left_feet: "Zurdo",
  right_feet: "Derecho",
  both_feet: "Ambidiestro",
};

export const nationalCategories = {
  male: { U15: "U15", U17: "U17", U19: "U19", U21: "U21" },
  female: { U17: "U17", U20: "U20" },
  none: { ninguna: "ninguna" },
};
