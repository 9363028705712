import { useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { tokens } from "../theme";
import ModalUser from "./player/ModalUser";
import { API_URL } from "../data/AppConstants";
import { IconButton } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useAuth } from "../context/AuthContext";

const Header = ({ title, subtitle, showButton, handleOnClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir el modal
  const handleOpenModal = () => {
    handleOnClick();
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <Box mb="30px">
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      </Box>
      {showButton && (
        <>
          <Box
            width="100px"
            height="40px"
            m="5"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            alignSelf="self-end"
            onClick={handleOpenModal}
          >
            <p
              style={{
                color: colors.grey[800],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Crear
            </p>
          </Box>
          {/* Modal */}
        </>
      )}
    </div>
  );
};

export default Header;
