import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const VerificacionesAfiliados = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [verificaciones, setVerificaciones] = useState([]);
  const { isMobile } = useAuth();
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  const [imageUrl, setImageUrl] = useState("");

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const approve = (userId, approve) => {
    fetch(`${API_URL}auth/panel/approve-verification-referrals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, approve }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast(
            approve === 1 ? "Verificación Aprobada" : "Verificación rechazada",
            "success"
          );
          fecthVerificaciones();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Error en el servidor, intentelo más tarde", "error");
      });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const fecthVerificaciones = () => {
    fetch(`${API_URL}auth/panel/verifications-referrals`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setVerificaciones(data.verificaciones.reverse());
        } else {
          setVerificaciones([]);
        }
      })
      .catch((err) => {
        setVerificaciones([]);
        console.log(err);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "name",
      headerName: "Usuario",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name}  ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Correo",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dni_frontal",
      headerName: "DNI frontal",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <img
              src={row.dni_frontal}
              onClick={() => {
                setImageUrl(row.dni_frontal);
                setOpenFullScreen(true);
              }}
              alt="DNI frontal min"
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
            />
            <a href={row.dni_frontal} download={true}>
              <IconButton>
                <FileDownloadIcon />
              </IconButton>
            </a>
          </Box>
        );
      },
    },
    {
      field: "dni_trasero",
      headerName: "DNI trasero",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <img
              src={row.dni_trasero}
              onClick={() => {
                setImageUrl(row.dni_trasero);
                setOpenFullScreen(true);
              }}
              alt="DNI frontal min"
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
            />
            <a href={row.dni_trasero} download={true}>
              <IconButton>
                <FileDownloadIcon />
              </IconButton>
            </a>
          </Box>
        );
      },
    },
    {
      field: "doc_036_cif",
      headerName: "documento 036 o CIF",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <img
              src={row.doc_036_cif}
              onClick={() => {
                setImageUrl(row.doc_036_cif);
                setOpenFullScreen(true);
              }}
              alt="DNI frontal min"
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
            />
            <a href={row.doc_036_cif} download={true}>
              <IconButton>
                <FileDownloadIcon />
              </IconButton>
            </a>
          </Box>
        );
      },
    },
    {
      field: "aprobado",
      headerName: "Aprobado",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return row.aprobado === 1 ? (
          <VerifiedIcon
            style={{
              color: colors.greenAccent[500],
            }}
          />
        ) : (
          <PendingIcon
            style={{
              color: colors.redAccent[500],
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {row.aprobado !== 1 && (
              <IconButton
                onClick={() => {
                  approve(row.user_id, 1);
                }}
                color="secondary"
                aria-label="Aprobar"
              >
                <CheckIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                approve(row.user_id, 0);
              }}
              color="secondary"
              aria-label="Rechazar"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fecthVerificaciones();
  }, []);

  return (
    <Box m="20px">
      <Header
        title="Verificaciones de afiliados"
        subtitle="Aprueba o rechaza las verificaciones de afiliados"
      />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Dialog open={openFullScreen} onClose={handleCloseFullScreen}>
        <DialogContent>
          <img
            src={imageUrl}
            alt="FullScreen DNI"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.primary[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={verificaciones}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default VerificacionesAfiliados;
