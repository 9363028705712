import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importa el estilo de ReactQuill
import { API_URL } from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";

const ModalSendEmail = ({ handleCloseModal, correo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [formValues, setFormValues] = useState({
    name: "",
    asunto: "",
    message: "",
    correo: correo,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleQuillChange = (value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      message: value,
    }));
  };

  const handleCreateAgent = () => {
    if (
      formValues.name === "" ||
      formValues.message === "" ||
      formValues.asunto === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/panel/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast("El mensaje se ha enviado con éxito.", "success");
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          } else {
            showToast("Error en el servidor, intentelo más tarde", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4" mb={2}>
        Enviar Correo
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="name"
        >
          Nombre
        </InputLabel>
        <Input
          id="name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#ffffffb3",
            },
          }}
          htmlFor="asunto"
        >
          Asunto
        </InputLabel>
        <Input
          id="asunto"
          name="asunto"
          value={formValues.asunto}
          onChange={handleChange}
        />
      </FormControl>
      <InputLabel
        sx={{
          "&.Mui-focused": {
            color: "#ffffffb3",
          },
          mb: 1,
          mt: 1,
        }}
        htmlFor="message"
      >
        Mensaje
      </InputLabel>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <ReactQuill
          value={formValues.message}
          onChange={handleQuillChange}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
        />
      </FormControl>

      <Box display="flex" justifyContent="space-around" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateAgent}
        >
          Enviar
        </Button>
        <Button variant="contained" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Box>
    </Box>
  );
};

export default ModalSendEmail;
