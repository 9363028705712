import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Videocam } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import DiscountIcon from "@mui/icons-material/Discount";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import { useAuth } from "../../context/AuthContext";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Cookies from "js-cookie";
import EmailIcon from "@mui/icons-material/Email";
import { API_URL } from "../../data/AppConstants";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [currentYear, setCurrentYear] = useState("2023");
  const [permisosSp, setPermisos] = useState([]);
  const { isAdmin, mobileMenu, toogleMobileMenu, isMobile, loggedUserId } =
    useAuth();
  const [showIframe, setShowIframe] = useState(false);
  const permisos2 = JSON.parse(localStorage.getItem("permisos"));
  console.log(loggedUserId);
  //const permisosSp = permisos2?.split(",");
  const toggleIframe = () => {
    setShowIframe(!showIframe);
  };
  const fetchPermisos = () => {
    fetch(`${API_URL}auth/panel/permisos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedUserId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setPermisos(data.permisos.split(","));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    setCurrentYear(year);
    fetchPermisos();
  }, []);

  return (
    <>
      {showIframe && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: "0",
            bottom: "0",
            top: "0",
            left: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "999",
          }}
        >
          <iframe
            src="https://webmail.bro.futbol"
            title="Webmail"
            width="80%"
            height="95%"
            frameBorder="0"
          />
          <CloseIcon
            sx={{
              alignSelf: "flex-start",
              marginTop: "20px",
              fontSize: "32px",
              cursor: "pointer",
            }}
            onClick={toggleIframe}
          />
        </Box>
      )}
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#1ce600 !important",
          },
          "& .pro-menu-item.active": {
            color: "#05ff00 !important",
          },
        }}
        style={{
          position: "relative",
          height: "100%",
          display: isMobile && mobileMenu ? "none" : "block",
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() =>
                isMobile ? toogleMobileMenu(true) : setIsCollapsed(!isCollapsed)
              }
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  {/* <Typography variant="h3" color={colors.grey[100]}>
                  CUVO
                </Typography> */}
                  <Box mb="25px">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        alt="profile-user"
                        width="140px"
                        height="auto"
                        src={`../../assets/logo.svg`}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Box>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              {isAdmin && (
                <>
                  {permisosSp?.includes("Panel de control") && (
                    <Item
                      title="Panel de control"
                      to="/dashboard"
                      icon={<HomeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    marginLeft={"15px"}
                    fontWeight={"bold"}
                  >
                    Sistema
                  </Typography>
                  {permisosSp?.includes("Jugadores") && (
                    <Item
                      title="Jugadores"
                      to="/players"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Agentes") && (
                    <Item
                      title="Agentes"
                      to="/agents"
                      icon={<SupportAgentIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Videos") && (
                    <Item
                      title="Videos"
                      to="/videos"
                      icon={<Videocam />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Consultas") && (
                    <Item
                      title="Consultas"
                      to="/consults"
                      icon={<HelpCenterIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Agentes Nuevos") && (
                    <Item
                      title="Agentes Nuevos"
                      to="/approve_agents"
                      icon={<FiberNewIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Verificaciones") && (
                    <Item
                      title="Verificaciones"
                      to="/verifications"
                      icon={<VerifiedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Correo") && (
                    <MenuItem
                      active={selected === "Correo"}
                      style={{
                        color: colors.grey[100],
                      }}
                      onClick={() => {
                        setSelected("Correo");
                        toggleIframe();
                      }}
                      icon={<EmailIcon />}
                    >
                      <Typography>Correo</Typography>
                    </MenuItem>
                  )}
                  <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    marginLeft={"15px"}
                    fontWeight={"bold"}
                  >
                    Pedidos
                  </Typography>
                  {permisosSp?.includes("Pedidos") && (
                    <Item
                      title="Pedidos"
                      to="/orders"
                      icon={<ReceiptIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Cupones") && (
                    <Item
                      title="Cupones"
                      to="/coupons"
                      icon={<DiscountIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    marginLeft={"15px"}
                    fontWeight={"bold"}
                  >
                    Afiliados
                  </Typography>
                  {permisosSp?.includes("Verificaciones Afiliados") && (
                    <Item
                      title="Verificaciones Afiliados"
                      to="/verifications-referrals"
                      icon={<WorkspacePremiumIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Afiliados") && (
                    <Item
                      title="Afiliados"
                      to="/members"
                      icon={<CardMembershipIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {permisosSp?.includes("Retiros") && (
                    <Item
                      title="Retiros"
                      to="/withdraws"
                      icon={<AccountBalanceIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}

                  <Box sx={{ height: "35px" }}></Box>
                  {permisosSp?.includes("Usuarios panel") && (
                    <Item
                      title="Usuarios panel"
                      to="/panel-users"
                      icon={<AdminPanelSettingsIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </>
              )}
              {!isAdmin && (
                <Item
                  title="Archivos"
                  to="/files"
                  icon={<FolderIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </Box>
            <MenuItem
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  style={{
                    paddingLeft: 0,
                    margin: "0px auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    color={colors.grey[200]}
                    textAlign="center"
                    paddingBottom="20px"
                  >
                    © {currentYear} Bró Football Platform. <br /> Todos los
                    derechos reservados. <br /> App desarrollada <br /> por{" "}
                    <span>
                      <strong>
                        <a
                          href="https://esloogan.com"
                          target="_blank"
                          rel="noreferrer noopener"
                          style={{ display: "inline-block" }}
                        >
                          Esloogan
                        </a>
                      </strong>
                    </span>
                  </Typography>
                </Box>
              )}
            </MenuItem>
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
