import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useAuth } from "../../context/AuthContext";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { logout, isAdmin, isMobile, mobileMenu, toogleMobileMenu } = useAuth();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      alignItems="center"
    >
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      {isMobile && mobileMenu && (
        <MenuOutlinedIcon
          onClick={() => {
            toogleMobileMenu(false);
          }}
        />
      )}
      <Typography color={colors.grey[100]} variant="h2" fontWeight="600">
        Bró Football Platform
      </Typography>
      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* {isAdmin && (
          <Link to="/form">
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          </Link>
        )} */}
        <IconButton onClick={() => logout()}>
          <LogoutOutlined />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
