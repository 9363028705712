import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import GradeIcon from "@mui/icons-material/Grade";
import { DataGrid, esES } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { API_URL } from "../../data/AppConstants";
import { useAuth } from "../../context/AuthContext";
import AlertDialog from "../../components/ChooseDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const Videos = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [videos, setVideos] = useState([]);
  const { isMobile } = useAuth();
  const [confirmDial, setConfirmDial] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [idToDelete, setIdToDelete] = useState(0);
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const handleOpenVideoDialog = (video_url) => {
    setOpenVideoDialog(true);
    setVideoUrl(video_url);
  };

  const handleCloseVideoDialog = () => {
    setOpenVideoDialog(false);
  };

  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleCloseToast = () => {
    setState({ ...state, open: false });
  };

  const getVideoType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    switch (extension) {
      case "mp4":
        return "video/mp4";
      case "webm":
        return "video/webm";
      case "ogg":
        return "video/ogg";
      default:
        return "";
    }
  };

  const fecthVideos = () => {
    fetch(`${API_URL}auth/all-videos`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.ok) {
          setVideos(data.videos);
        } else {
          setVideos([]);
        }
      })
      .catch((err) => {
        setVideos([]);
        console.log(err);
      });
  };
  const handleDelete = () => {
    setConfirmDial(false);
    fetch(`${API_URL}auth/delete-video`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ videoId: idToDelete }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          showToast("Video eliminado exitosamente!", "success");
          fecthVideos();
        } else {
          showToast("Error en el servidor, intentelo más tarde", "error");
        }
      })
      .catch((err) => {
        showToast("Error en el servidor, intentelo más tarde", "error");
        console.log(err);
      });
  };
  const handleCloseDial = () => {
    setConfirmDial(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: isMobile ? 1 : "none" },
    {
      field: "video_url",
      headerName: "Video",
      flex: isMobile ? "none" : 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              handleOpenVideoDialog(row.video_url);
            }}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        );
      },
    },
    {
      field: "name",
      headerName: "Usuario",
      flex: isMobile ? "none" : 1,
      headerAlign: "left",
      align: "left",
      renderCell: ({ row }) => {
        return <Typography>{`${row.name}  ${row.lastname}`}</Typography>;
      },
    },
    {
      field: "description",
      headerName: "Descripcion",
      flex: isMobile ? "none" : 1,
    },
    {
      field: "destacado",
      headerName: "Destacado",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? "none" : 1,
      renderCell: ({ row }) => {
        return row.destacado === 1 ? (
          <GradeIcon
            style={{
              color: colors.greenAccent[500],
            }}
          />
        ) : (
          <Box />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => {
                setIdToDelete(row.id);
                setConfirmDial(true);
              }}
              color="secondary"
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    fecthVideos();
  }, []);

  return (
    <Box m="20px">
      <Header title="Videos" subtitle="Revisa los videos" />

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <AlertDialog
        open={confirmDial}
        handleClose={handleCloseDial}
        title={"¿Está seguro de borrar este video?"}
        content={"El usuario ya no lo verá y no podrá recuperarlo"}
        handleConfirm={handleDelete}
      />
      <Dialog open={openVideoDialog} onClose={handleCloseVideoDialog}>
        <DialogContent>
          <video style={{ width: "100%", height: "600px" }} controls>
            <source src={videoUrl} type={getVideoType(videoUrl)} />
            Tu navegador no soporta la reproducción de videos.
          </video>
        </DialogContent>
      </Dialog>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: `${colors.primary[900]} !important`,
            fontWeight: 700,
            fontSize: "14px",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiTablePagination-toolbar": {
            color: "#000 !important",
          },
          "& .MuiDataGrid-selectedRowCount": {
            color: "#000 !important",
          },
        }}
      >
        <DataGrid
          rows={videos}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="999"
                position="relative"
              >
                No hay resultados para el filtro aplicado
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Videos;
