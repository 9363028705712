import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Typography,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  API_URL,
  countries,
  provincesByCountry,
  categorias,
  posiciones,
  selecciones,
  piesDominantes,
  nationalCategories,
} from "../../data/AppConstants";
import { tokens } from "../../theme";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "dayjs/locale/es";
dayjs.locale(esLocale);

const ModalUser = ({ handleCloseModal, usuario = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isMobile } = useAuth();
  const [state, setState] = useState({
    open: false,
    message: "Snack",
    type: "success",
  });

  const [formValues, setFormValues] = useState({
    userId: usuario.user_id,
    Name: usuario.name,
    LastName: usuario.lastname,
    DNI: usuario.DNI,
    Email: usuario.email,
    Altura: usuario.altura,
    Birthday: dayjs(usuario.birthday.split("T")[0]),
    Categoria: usuario.categoria,
    CategoriaSeleccion: usuario.categoria_seleccion,
    Club: usuario.club,
    codigo_afiliado_referido: usuario.codigo_afiliado_referido,
    LogrosIndividuales: usuario.logros_individuales,
    Pais: usuario.pais,
    PieDominante: usuario.pie_dominante,
    PosicionJugador: usuario.posicion_jugador,
    Provincia: usuario.provincia,
    SeleccionNacional: usuario.seleccion_nacional,
    status: usuario.status,
    stripe_customer_id: usuario.stripe_customer_id,
    suscription: usuario.suscription,
    suscription_id: usuario.suscription_id,
    Direccion: usuario.direccion,
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log(formValues);
  };

  const handleChangeDate = (value, newdate) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [value]: newdate,
    }));
  };
  const showToast = (message, type) => {
    setState({ open: true, message, type });
  };

  const handleEditPlayer = () => {
    if (
      formValues.Name === "" ||
      formValues.LastName === "" ||
      formValues.DNI === "" ||
      formValues.Email === "" ||
      formValues.Altura === "" ||
      formValues.Birthday === "" ||
      formValues.Categoria === "" ||
      formValues.CategoriaSeleccion === "" ||
      formValues.Pais === "" ||
      formValues.PieDominante === "" ||
      formValues.PosicionJugador === "" ||
      formValues.Provincia === "" ||
      formValues.SeleccionNacional === "" ||
      formValues.Direccion === ""
    ) {
      showToast("Por favor, complete todos los campos.", "error");
    } else {
      fetch(`${API_URL}auth/edit-player`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            showToast(data.message, "success");
            handleCloseModal();
          } else {
            showToast("Error en el servidor, intentelo más tarde", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("Error en el servidor, intentelo más tarde", "error");
        });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: colors.primary[800],
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        minWidth: "400px",
        overflowY: isMobile ? "scroll" : "none",
        height: isMobile ? "80%" : "auto",
      }}
    >
      <Typography variant="h4" mb={2}>
        Editar Jugador
      </Typography>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={state.open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant="outlined"
          sx={{ width: "100%" }}
        >
          {state.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6} alignItems={"flex-start"}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Name"
            >
              Nombre
            </InputLabel>
            <Input
              id="Name"
              name="Name"
              value={formValues.Name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="LastName"
            >
              Apellidos
            </InputLabel>
            <Input
              id="LastName"
              name="LastName"
              value={formValues.LastName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Email"
            >
              Correo
            </InputLabel>
            <Input
              id="Email"
              name="Email"
              value={formValues.Email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="DNI"
            >
              DNI
            </InputLabel>
            <Input
              id="DNI"
              name="DNI"
              value={formValues.DNI}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Direccion"
            >
              Direccion
            </InputLabel>
            <Input
              id="Direccion"
              name="Direccion"
              value={formValues.Direccion}
              onChange={handleChange}
            />
          </FormControl>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={esLocale}
            adapterLocale="es"
          >
            <DemoContainer components={["MobileDateTimePicker"]}>
              <DemoItem label="Fecha de nacimiento">
                <DatePicker
                  value={formValues.Birthday}
                  onChange={(newValue) =>
                    handleChangeDate("Birthday", newValue)
                  }
                  format="DD/MM/YYYY"
                  localeText={{
                    clearButtonLabel: "Vaciar",
                    todayButtonLabel: "Hoy",
                    okButtonLabel: "Guardar",
                    cancelButtonLabel: "Cancelar",
                    toolbarTitle: "Selecciona fecha y hora",
                  }}
                  slotProps={{
                    color: "#fcfcfc",

                    clearIcon: {
                      style: {
                        color: "#000000",
                      },
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              País
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="Pais"
                name="Pais"
                value={formValues.Pais || ""}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(countries).map((countryKey) => (
                  <MenuItem
                    key={`pais-${countryKey}`}
                    value={countryKey}
                    style={{
                      backgroundColor:
                        formValues.Pais === countryKey
                          ? colors.greenAccent[500]
                          : "transparent",
                      color: formValues.Pais === countryKey ? "#000" : "#fff",
                    }}
                  >
                    {countries[countryKey] || "Desconocido"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Provincia
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="Provincia"
                name="Provincia"
                value={formValues.Provincia || ""}
                onChange={handleChange}
                variant="outlined"
              >
                {provincesByCountry[formValues.Pais]
                  ? Object.keys(provincesByCountry[formValues.Pais]).map(
                      (state) => (
                        <MenuItem
                          key={`provincia-${state}`}
                          value={state}
                          style={{
                            backgroundColor:
                              formValues.Provincia === state
                                ? colors.greenAccent[500]
                                : "transparent",
                            color:
                              formValues.Provincia === state ? "#000" : "#fff",
                          }}
                        >
                          {provincesByCountry[formValues.Pais][state]}
                        </MenuItem>
                      )
                    )
                  : formValues.Pais === "" ||
                    !provincesByCountry[formValues.Pais]
                  ? Object.keys(provincesByCountry["spain"]).map((state) => (
                      <MenuItem
                        key={`provincia-${state}`}
                        value={state}
                        style={{
                          backgroundColor:
                            formValues.Provincia === state
                              ? colors.greenAccent[500]
                              : "transparent",
                          color:
                            formValues.Provincia === state ? "#000" : "#fff",
                        }}
                      >
                        {provincesByCountry["spain"][state]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="suscription"
            >
              Suscripcion
            </InputLabel>
            <Input
              id="suscription"
              name="suscription"
              value={formValues.suscription ?? ""}
              onChange={handleChange}
              readOnly={true}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="suscription_id"
            >
              Id de suscripcion
            </InputLabel>
            <Input
              id="suscription_id"
              name="suscription_id"
              readOnly={true}
              value={formValues.suscription_id ?? ""}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="status"
            >
              Estado
            </InputLabel>
            <Input
              id="status"
              name="status"
              readOnly
              value={formValues.status == 1 ? "Activo" : "Inactivo"}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="referral_code"
            >
              Codigo de referido
            </InputLabel>
            <Input
              id="referral_code"
              name="referral_code"
              value={formValues.referral_code ?? ""}
              onChange={handleChange}
              readOnly={true}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="stripe_customer_id"
            >
              Id usuario Stripe
            </InputLabel>
            <Input
              id="stripe_customer_id"
              name="stripe_customer_id"
              value={formValues.stripe_customer_id ?? ""}
              onChange={handleChange}
              readOnly={true}
            />
          </FormControl>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Categoria
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="Categoria"
                name="Categoria"
                value={formValues.Categoria}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(categorias).map((categoria) => (
                  <MenuItem
                    key={`categoria-${categoria}`}
                    value={categoria}
                    style={{
                      backgroundColor:
                        formValues.Categoria === categoria
                          ? colors.greenAccent[500]
                          : "transparent",
                      color:
                        formValues.Categoria === categoria ? "#000" : "#fff",
                    }}
                  >
                    {categorias[categoria] || "Desconocido"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Altura"
            >
              Altura
            </InputLabel>
            <Input
              id="Altura"
              name="Altura"
              value={formValues.Altura}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="Club"
            >
              Entidad Deportiva
            </InputLabel>
            <Input
              id="Club"
              name="Club"
              value={formValues.Club ?? ""}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
              }}
              htmlFor="LogrosIndividuales"
            >
              Logros Individuales
            </InputLabel>
            <Input
              id="LogrosIndividuales"
              name="LogrosIndividuales"
              value={formValues.LogrosIndividuales ?? ""}
              onChange={handleChange}
            />
          </FormControl>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Pie Dominante
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="PieDominante"
                name="PieDominante"
                value={formValues.PieDominante}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(piesDominantes).map((pie) => {
                  return (
                    <MenuItem
                      key={`pie-${pie}`}
                      value={pie}
                      style={{
                        backgroundColor:
                          formValues.PieDominante === pie
                            ? colors.greenAccent[500]
                            : "transparent",
                        color:
                          formValues.PieDominante === pie ? "#000" : "#fff",
                      }}
                    >
                      {piesDominantes[pie]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Seleccion Nacional
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="SeleccionNacional"
                name="SeleccionNacional"
                value={formValues.SeleccionNacional}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(selecciones).map((seleccion) => {
                  return (
                    <MenuItem
                      key={`seleccion-${seleccion}`}
                      value={seleccion}
                      style={{
                        backgroundColor:
                          formValues.SeleccionNacional === seleccion
                            ? colors.greenAccent[500]
                            : "transparent",
                        color:
                          formValues.SeleccionNacional === seleccion
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      {selecciones[seleccion]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Categoria Seleccion
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="CategoriaSeleccion"
                name="CategoriaSeleccion"
                value={formValues.CategoriaSeleccion}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(
                  nationalCategories[formValues.SeleccionNacional] ||
                    nationalCategories["male"]
                ).map((category) => (
                  <MenuItem
                    key={`category-${category}`}
                    value={category}
                    style={{
                      backgroundColor:
                        formValues.CategoriaSeleccion === category
                          ? colors.greenAccent[500]
                          : "transparent",
                      color:
                        formValues.CategoriaSeleccion === category
                          ? "#000"
                          : "#fff",
                    }}
                  >
                    {nationalCategories[formValues.SeleccionNacional]?.[
                      category
                    ] || nationalCategories["male"][category]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              mb: "10px",
            }}
          >
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#ffffffb3",
                },
                fontSize: "15px",
              }}
            >
              Posicion Jugador
            </InputLabel>
            <FormControl fullWidth>
              <Select
                id="PosicionJugador"
                name="PosicionJugador"
                value={formValues.PosicionJugador}
                onChange={handleChange}
                variant="outlined"
              >
                {Object.keys(posiciones).map((position) => {
                  return (
                    <MenuItem
                      key={`position -${position}`}
                      value={position}
                      style={{
                        backgroundColor:
                          formValues.PosicionJugador === position
                            ? colors.greenAccent[500]
                            : "transparent",
                        color:
                          formValues.PosicionJugador === position
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      {posiciones[position]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-around" mt={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditPlayer}
            >
              Guardar
            </Button>
            <Button variant="contained" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalUser;
